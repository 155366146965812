<template>
  <div>

  <!--Menu Admin -->
  <div class="menuAdmin container" v-if="showAdmin == true">
    <div class="row p-3">
      <div class="col-lg-6">
        <button type="button" class="m-1 btn btn-sm btn-success" @click="showModal('c')">
          Nuevo Curso
        </button>
        <button type="button" class="m-1 btn btn-sm btn-success" @click="showModal('a')">
          Nueva Audición
        </button>
      </div>
      <div class="col-lg-6">
        <button class="btn btn-danger float-end btn-sm" @click="terminar()">Terminar</button>
      </div>
    </div>
  </div>

  <div class="cuerpo">
    <Menu :type='5'/>
    <div class="texto-hero pt-4 animate__animated animate__fadeIn">
        <h3 class="pt-5 titulo1 s23 fw400 text-center"></h3>
        
        <h2 class="white padding texto-times-italic s38 fw400" style="position:relative;line-height: 30px">
          <span v-if="idioma == 'ES'">Audiciones y Cursos</span>
          <span v-else>Auditions and Courses</span>
        </h2>

        <h2 class="texto-normal-bold white padding upper s80">
         <span v-if="idioma == 'ES'"> convocatorias </span>
         <span v-else> announcements </span>
        </h2>
    </div>
  </div>

  <div class="separador"></div>

  <div class="container-fluid">
      <div class="padding-all">
        <p class="white texto-normal-bold fw900 s56">
          <span v-if="idioma=='ES'">CURSOS</span>
          <span v-else>COURSES</span>
        </p>
      
        <div class="row paddingizq white pt-3">
            
            <div class="col-lg-4 col-md-6 col-sm-6 pb-lg-1 pb-5 cuerpo_cursos" v-for="f in cursos" :key = "f.id_cursos">
                
                <div v-if="showAdmin == true" class="">
                  <p>
                    <button class="m-1 btn btn-primary btn-sm" @click="showModalEdicion(f)">
                      <i class="fas fa-edit"></i>
                    </button>
                    <button class="m-1 btn btn-primary btn-sm" @click="showModalEdicionFoto(f)">
                      <i class="fas fa-edit"></i> Foto
                    </button>
                    <button class="m-1 btn btn-danger btn-sm" @click="borrar(f)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </p>
                </div>
                
                <router-link :to="{name:'ConvocatoriasDetalle',params:{id:f.id_convocatoria}}">
                  <img :src="url + 'uploads/' + f.url_img" class="img-concierto3">  
                </router-link>

                <div>
                  <p class="pt-4 texto-normal-bold fw800 s18">
                    <span style="text-transform: capitalize;"> {{getDiaNombre(f.fecha)}} </span> 
                    {{getDia(f.fecha)}} 
                    {{getMes(f.fecha)}}, 
                    {{getAno(f.fecha)}}
                  </p>
                  
                  <router-link class="href-chica link" :to="{name:'ConvocatoriasDetalle',params:{id:f.id_convocatoria}}">
                    <p class="texto-times-bold s24" style="line-height:110%">
                      <span v-if="idioma == 'ES'"> {{f.titulo}} </span>
                      <span v-else> {{f.titulo_eng}} </span>
                    </p>
                  </router-link>
                  
                  <p class="pt-2 texto-normal s18" style="line-height:139%">
                    <span v-if="idioma == 'ES'"> {{f.descr_sm}} </span>
                    <span v-else> {{f.descr_sm_eng}} </span>
                  </p>
                  
                  <p class="pt-3">
                    <router-link class="href-chica s20 link" :to="{name:'ConvocatoriasDetalle',params:{id:f.id_convocatoria}}">
                      {{leermas}}
                    </router-link> &nbsp;&nbsp;&nbsp;
                  </p>
                </div>
            </div>
        </div>
      </div>
  </div>

  <div class="container-fluid">
      <div class="padding-all">
        <p class="white texto-normal-bold s43 upper">
          <span v-if="idioma=='ES'">audiciones</span>
          <span v-else>auditions</span>
        </p>
        <div class="row paddingizq white pt-3">
            <div class="col-lg-4 col-md-6 col-sm-6 cuerpo_cursos" v-for="f in audiciones" :key="f.id_convocatorias">
                
                <div v-if="showAdmin == true" class="">
                  <p>
                    <button class="m-1 btn btn-primary btn-sm"  @click="showModalEdicion(f)">
                      <i class="fas fa-edit"></i>
                    </button>
                    <button class="m-1 btn btn-primary btn-sm" @click="showModalEdicionFoto(f)">
                      <i class="fas fa-edit"></i> Foto
                    </button>
                    <button class="m-1 btn btn-danger btn-sm" @click="borrar(f)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </p>
                </div>
                
                <router-link :to="{name:'ConvocatoriasDetalle',params:{id:f.id_convocatoria}}">
                  <img :src="url + 'uploads/' + f.url_img" class="img-concierto3">  
                </router-link>

                <div>
                  <p class="pt-4 texto-normal-bold fw800 s18">
                    <span style="text-transform: capitalize;"> {{getDiaNombre(f.fecha)}} </span> 
                    {{getDia(f.fecha)}} 
                    {{getMes(f.fecha)}}, 
                    {{getAno(f.fecha)}}
                  </p>
                  
                  <router-link class="href-chica link" :to="{name:'ConvocatoriasDetalle',params:{id:f.id_convocatoria}}">
                    <p class="texto-times-bold s24" style="line-height:110%">
                      <span v-if="idioma == 'ES'"> {{f.titulo}} </span>
                      <span v-else> {{f.titulo_eng}} </span>
                    </p>
                  </router-link>

                  <p class="pt-2 texto-normal s18" style="line-height:139%">
                    <span v-if="idioma == 'ES'"> {{f.descr_sm}} </span>
                    <span v-else> {{f.descr_sm_eng}} </span>
                  </p>
                  
                  <p class="pt-3">
                    <router-link class="href-chica s20 link" :to="{name:'ConvocatoriasDetalle',params:{id:f.id_convocatoria}}">
                      {{leermas}}
                    </router-link>
                  </p>
                </div>
            </div>
        </div>
      </div>
        
  </div>

<!-- Modal Alta -->
<div class="modal fade" ref="exampleModal" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Alta {{tipo_text}}</h5>
        <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
      </div>
      <form @submit="altaImg">
        <div class="modal-body">
          <div class="container-fluid">
          
            <div class="row">
              <div class="col-lg-6">
                <span class="badge bg-secondary">Fecha</span>
                <input type="date" class="mt-1 form-control" required v-model="fecha">
              </div>
              <div class="col-lg-6">
                <span class="badge bg-secondary">Imagen</span>
                <input type="file" class="mt-1 form-control" required @change="addFile">
              </div>
            </div>


            <div class="row pt-2">
              <div class="col-lg-6">
                <span class="badge bg-secondary">Titulo</span>
                <input type="text" class="mt-1 form-control" required v-model="titulo">
              </div>
              <div class="col-lg-6">
                <span class="badge bg-secondary">Titulo Inglés</span>
                <input type="text" class="mt-1 form-control" required v-model="titulo_eng">
              </div>
            </div>
            
            <div class="row pt-2">
              <div class="col-lg-12">
                <span class="badge bg-secondary">Descripción</span>
                <textarea class="mt-1 form-control" required v-model="descr" rows="2"></textarea>
              </div>
            </div>

            <div class="row pt-2">
              <div class="col-lg-12">
                <span class="badge bg-secondary">Descripción Inglés</span>
                <textarea class="mt-1 form-control" required v-model="descr_eng" rows="2"></textarea>
              </div>
            </div>


          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal.hide()">Cerrar</button>
          <button type="submit" class="btn btn-success">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal Editar -->
<div class="modal fade" ref="editarModal" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar</h5>
        <button type="button" class="btn-close" @click="modal2.hide()" aria-label="Close"></button>
      </div>
      <form @submit="editar">
        <div class="modal-body">
          <div class="container-fluid">
          
            <div class="row">
              <div class="col-lg-6">
                <span class="badge bg-secondary">Fecha</span>
                <input type="date" class="mt-1 form-control" required v-model="efecha">
              </div>
            </div>

            <div class="row pt-2">
              <div class="col-lg-6">
                <span class="badge bg-secondary">Titulo</span>
                <input type="text" class="mt-1 form-control" required v-model="etitulo">
              </div>
              <div class="col-lg-6">
                <span class="badge bg-secondary">Titulo Inglés</span>
                <input type="text" class="mt-1 form-control" required v-model="etitulo_eng">
              </div>
            </div>
            
            <div class="row pt-2">
              <div class="col-lg-12">
                <span class="badge bg-secondary">Descripción</span>
                <textarea class="mt-1 form-control" required v-model="edescr" rows="2"></textarea>
              </div>
            </div>

            <div class="row pt-2">
              <div class="col-lg-12">
                <span class="badge bg-secondary">Descripción Inglés</span>
                <textarea class="mt-1 form-control" required v-model="edescr_eng" rows="2"></textarea>
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal2.hide()">Cerrar</button>
          <button type="submit" class="btn btn-primary">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal Editar Foto-->
<div class="modal fade" ref="modalFoto" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar Foto</h5>
        <button type="button" class="btn-close" @click="modal3.hide()" aria-label="Close"></button>
      </div>
      <form @submit="editar_foto_upload">
        <div class="modal-body">
          <div class="container-fluid">
          
            <div class="row">
              <div class="col-lg-6">
                <span class="badge bg-secondary">Nueva Img</span>
                <input type="file" class="mt-1 form-control" required @change="addFile"> 
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal3.hide()">Cerrar</button>
          <button type="submit" class="btn btn-primary">Editar</button>
        </div>
      </form>
    </div>
  </div>
</div>

</div>
</template>


<script>
import Menu from '@/components/Menu'
import { Modal } from 'bootstrap'
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'

export default {
name: 'Integrantes',
mixins: [ globalFunctions ],
components:{ Menu },
data () {
  return {
    idioma: localStorage.idioma,
    modal: null,
    modal2: null,
    modal3: null,
    showAdmin: '',
    fecha: '',
    titulo: '',
    titulo_eng: '',
    descr: '',
    descr_eng: '',
    efecha: '',
    etitulo: '',
    etitulo_eng: '',
    edescr: '',
    edescr_eng: '',
    tipo: '',
    tipo_text: '',
    audiciones: '',
    cursos: '',
    selectedFile: '',
    fileName: '',
    id_convocatoria: '',
    leermas: 'Leer Más'
  }
},
computed:{
  ...mapState(['url'])
},
methods: {
  getData(){

    let data = {
      flag: 0
    }

    this.axios.post(this.url + "getConvocatoria.php", data).then((responce) => {
        //console.log(responce.data);
        this.cursos = responce.data;
    });

    let data2 = {
      flag: 1
    }

    this.axios.post(this.url + "getConvocatoria.php", data2).then((responce) => {
        //console.log(responce.data);
        this.audiciones = responce.data;
    });

  },

  borrar(f){
    let data = {
      flag: 2,
      id: f.id_convocatoria,
    }

    this.axios.post(this.url + "altaConvocatoria.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.getData();
          this.msjOk("Borrado con éxito");
        }
    });
  },

  alta(file){
    
    let data = {
      flag: 0,
      fecha: this.fecha,
      titulo: this.titulo,
      titulo_eng: this.titulo_eng,
      descr: this.descr,
      descr_eng: this.descr_eng,
      tipo: this.tipo,
      file
    }
    
    var valid_date = this.checkDate(data.fecha);
    
    if (valid_date == 1){
      this.msjError("Fecha debe ser mayor a hoy");
      return;
    }

    this.axios.post(this.url + "altaConvocatoria.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.modal.hide();
          this.getData();
          this.msjOk(this.tipo_text + " Insertado con éxito");
          this.fecha = "";
          this.titulo = "";
          this.titulo_eng = "";
          this.descr = "";
          this.descr_eng = "";
          this.tipo = "";
        }
    });
  },
  altaImg(e){
    e.preventDefault();
    
    if(this.selectedFile != null){
        const fd = new FormData();
        fd.append('file',this.selectedFile, this.selectedFile.name);
        
        this.axios.post(this.url + 'uploadNew.php', fd, {header:{'Content-Type': 'multipart/form-data'}}).then((responce) =>{
          //console.log(responce);
          if(responce.data.res == '200'){
            let file = responce.data.nombre;
            this.alta(file);
          }
        })
    };
    
  },

  editar(e){
    e.preventDefault();
    
    let data = {
      flag: 4,
      fecha: this.efecha,
      titulo: this.etitulo,
      titulo_eng: this.etitulo_eng,
      descr: this.edescr,
      descr_eng : this.edescr_eng,
      id: this.id_convocatoria
    }

    var valid_date = this.checkDate(data.fecha);
    
    if (valid_date == 1){
      this.msjError("Fecha debe ser mayor a hoy");
      return;
    }

    this.axios.post(this.url + "altaConvocatoria.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.modal2.hide();
          this.getData();
          this.msjOk("Editado con éxito");
        }
    });
  },

  addFile(event){
    this.selectedFile = event.target.files[0];
    this.fileName = this.selectedFile.name;
  },

  showModalEdicion(f){
    //console.log(f);
    this.modal2.show();

    this.efecha = f.fecha;
    this.etitulo = f.titulo;
    this.etitulo_eng = f.titulo_eng;
    this.edescr = f.descr_sm;
    this.edescr_eng = f.descr_sm_eng;
    this.id_convocatoria = f.id_convocatoria;
  },

  editar_foto_upload(e){
    e.preventDefault();

    if(this.selectedFile != null){
        const fd = new FormData();
        fd.append('file',this.selectedFile, this.selectedFile.name);
        
        this.axios.post(this.url + 'uploadNew.php', fd, {header:{'Content-Type': 'multipart/form-data'}}).then((responce) =>{
          //console.log(responce);
          if(responce.data.res == '200'){
            let file = responce.data.nombre;
            this.editar_foto_alta(file);
          }
        })
    };
  },

  editar_foto_alta(file){
    let data = {
      flag: 3,
      id: this.id_convocatoria,
      ex_img: this.ex_img,
      file
    }
    
    this.axios.post(this.url + "altaConvocatoria.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.modal3.hide();
          this.getData();
          this.msjOk();
        }
    });
  },

  showModalEdicionFoto(f){
    this.modal3.show();
    this.id_convocatoria = f.id_convocatoria;
    this.ex_img = f.url_img;
  },

  showModal(tipo){
    this.tipo = tipo;
    console.log(tipo);
    this.modal.show();

    if(this.tipo == 'a'){
      this.tipo_text = "Audición"; 
    }else{
      this.tipo_text = "Curso";
    }
  },
  getEnglish(){
    this.leermas = 'See More'
  },
  terminar(){
    this.showAdmin = false;
    this.setInit();
  },
  checkToken(){
      const token = localStorage.token;
  
      let data = {
          token: token,
      }

      this.axios.post(this.url + "checkToken.php", data).then((responce) => {
          if(responce.data == 200){
              this.showAdmin = true;
          }else{
              this.showAdmin = false;
          }
      });
            
  }
},
mounted(){
  this.modal = new Modal(this.$refs.exampleModal)
  this.modal2 = new Modal(this.$refs.editarModal)
  this.modal3 = new Modal(this.$refs.modalFoto)
  
  this.checkToken();

  window.scrollTo(0, 0);
  this.getData();
  
  if(this.idioma == 'EN'){
    this.getEnglish();
  }
}
}
</script>


<style scoped>

.cuerpo{
  background:/* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.7), 
          rgba(0, 0, 0, 0.7)
        ),
        url('../assets/osa_convocatorias_cover.jpg');
    background-size: cover;
    position: relative;
    min-height: 80vh;
}

.cuerpo_cursos{
  padding-right:35px
}

.section_convocatorias{
  padding: 8%;
}

.img-concierto3{
  height: 458px;
  width: 100%;
  object-fit: cover;
}

.img-concierto3:hover{
  filter: brightness(70%);
  transition: 1s;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 700px) {
  
  .cuerpo_cursos{
    padding-right:55px
  }

}

</style>