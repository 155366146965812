<template>
  <div>

  <!--Menu Admin -->
  <div class="menuAdmin container" v-if="showAdmin == true">
    <div class="row p-3">
      <div class="col-6">
        <button type="button" class="btn btn-sm btn-success" @click="modal.show()">
          Nueva Galeria
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-danger float-end btn-sm" @click="terminar()">Terminar</button>
      </div>
    </div>
  </div>

  <!--Galeria Titulo-->
  <div class="cuerpo">
    <Menu :type='4'/>
    <div class="texto-hero pt-4 animate__animated animate__fadeIn">
        <div class="pt-5 padding">
          <h2 class="titulo2 s80 txt_000" v-if="idioma == 'ES'" >GALERIA<span class="texto-times-italic s38 fw400 txt_001"> y </span> <br> NOTICIAS</h2>
          <h2 class="titulo2 s80 txt_000" v-else>GALLERY<span class="texto-times-italic s38 fw400 txt_001"> & </span> <br> NEWS</h2>
        </div>
    </div>
  </div>


  <div class="separador"></div>

  <!--Ultimas Entradas -->
  <div class="container-fluid white">
    <div class="cuerpo_ultima_entreda">
      <p class="text-center texto-normal-bold s43">
        <span v-if="idioma == 'ES'">ÚLTIMA ENTRADA</span>
        <span v-else>LAST ENTRY</span>
      </p>
    
      <div class="row pt-5" v-for="f in ultima_entrada" :key="f.id_galeria">
        <div class="col-lg-7 col-md-6 col-12" style="padding:unset">
          <router-link :to="{name:'GaleriaDetalle',params:{id:f.id_galeria}}">
            <img :src="url + 'uploads/' + f.url_img" class="img">
          </router-link>
        </div>
        <div class="col-lg-5 col-md-6 col-12">
          <div class="px-lg-2 pt-md-1 pt-lg-1 pt-5 txt_ultima_entrada">
            <p class="texto-normal-bold s18 fw800">
              <span style="text-transform: capitalize;"> {{getDiaNombre(f.fecha_galeria)}} </span> 
              {{getDia(f.fecha_galeria)}} 
              {{getMes(f.fecha_galeria)}},
              {{getAno(f.fecha_galeria)}}
            </p>
            
            <router-link class="link href-chica" :to="{name:'GaleriaDetalle',params:{id:f.id_galeria}}"> 
              <p class="texto-times-bold s36" style="line-height: 39px">
                <span v-if="idioma == 'ES'"> {{f.titulo}} </span>
                <span v-else> {{f.titulo_eng}} </span>
              </p>
            </router-link>

            <p class="texto-normal s18" style="line-height:139%">
              <span v-if="idioma == 'ES'"> {{f.resumen}} </span>
              <span v-else> {{f.resumen_eng}}</span>
            </p>
            <p class="pt-lg-5 pt pt-1">
              <router-link class="link href-chica s20" :to="{name:'GaleriaDetalle',params:{id:f.id_galeria}}">{{leermas}}</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    
  </div>


  <div class="separador"></div>
  
  <!--Lista Galeria-->
  <div class="container-fluid">
      <div class="padding-all">
        <div class="row white pt-5">
            <div class="col-lg-4 col-md-6 pt-5" v-for="f in galeria" :key = "f.id_galeria">  
              <div class="cuerpo-galerias pb-5">
                
                <p v-if="showAdmin == true">
                  <button class="m-1 btn btn-primary btn-sm" @click="showModalEdicion(f)">
                    <i class="fas fa-edit"></i>
                  </button>
                  <button class="m-1 btn btn-primary btn-sm" @click="showModalEdicionFoto(f)">
                    <i class="fas fa-edit"></i> Foto
                  </button>
                  <button class="m-1 btn btn-danger btn-sm" @click="borrar(f)">
                    <i class="fas fa-trash"></i>
                  </button>
                </p>

                <router-link :to="{name:'GaleriaDetalle',params:{id:f.id_galeria}}">
                  <img :src="url + 'uploads/' + f.url_img" class="img-concierto_001">
                </router-link>
                
                <p class="pt-3 texto-normal-bold fw800 s18">
                  <span style="text-transform: capitalize;"> {{getDiaNombre(f.fecha_galeria)}} </span> 
                  {{getDia(f.fecha_galeria)}} 
                  {{getMes(f.fecha_galeria)}}, 
                  {{getAno(f.fecha_galeria)}}
                </p>
                
                <router-link class="link href-chica" :to="{name:'GaleriaDetalle',params:{id:f.id_galeria}}"> 
                  <p class="texto-times-bold s24" style="line-height:110%">
                    <span v-if="idioma == 'ES'"> {{f.titulo}} </span>
                    <span v-else> {{f.titulo_eng}} </span>
                  </p>
                </router-link>
                
                <div class="parrafo_body">
                  <p class="pt-2 texto-normal s18" style="line-height:139%">
                    <span v-if="idioma == 'ES'"> {{f.resumen}} </span>
                    <span v-else> {{f.resumen_eng}}</span>
                  </p>
                </div>
                
                <p class="pt-3">
                  <router-link class="href-chica s20 link" :to="{name:'GaleriaDetalle',params:{id:f.id_galeria}}">
                    {{leermas}}
                  </router-link> &nbsp;&nbsp;&nbsp;
                </p>

              </div>
            </div>
        </div>
      </div>
  </div>


<!-- Modal Alta -->
<div class="modal fade" ref="exampleModal" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Nueva Galeria</h5>
        <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
      </div>
      <form @submit="altaImg">
        <div class="modal-body">
          <div class="container-fluid">
          
            <div class="row">
              <!--<div class="col-lg-6">
                <span class="badge bg-secondary">Fecha</span>
                <input type="date" class="mt-1 form-control" required v-model="fecha">
              </div>-->
              <div class="col-lg-6">
                <span class="badge bg-secondary">Imagen</span>
                <input type="file" class="mt-1 form-control" required @change="addFile">
              </div>
            </div> <br>

            <div class="row">
              <div class="col-lg-6">
                <span class="badge bg-secondary">Titulo</span>
                <input type="text" class="mt-1 form-control" required v-model="titulo">
              </div>
              <div class="col-lg-6">
                <span class="badge bg-secondary">Titulo Inglés</span>
                <input type="text" class="mt-1 form-control" required v-model="titulo_eng">
              </div>
            </div> <br>

            <div class="row">
              <div class="col-lg-6">
                <span class="badge bg-secondary">Resumen</span>
                <textarea rows="3" class="mt-1 form-control" required maxlength="100" v-model="resumen" />
              </div>
              <div class="col-lg-6">
                <span class="badge bg-secondary">Resumen Ing</span>
                <textarea rows="3" class="mt-1 form-control" required maxlength="100" v-model="resumen_eng" />
              </div>
            </div> <br>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal.hide()">Cerrar</button>
          <button type="submit" class="btn btn-success">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal Editar -->
<div class="modal fade" ref="editarModal" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar Galeria</h5>
        <button type="button" class="btn-close" @click="modal2.hide()" aria-label="Close"></button>
      </div>
      <form @submit="editar">
        <div class="modal-body">
          <div class="container-fluid">
          
            <div class="row">
              <!--<div class="col-lg-6">
                <span class="badge bg-secondary">Fecha</span>
                <input type="date" class="mt-1 form-control" required v-model="efecha">
              </div>-->
            </div>

            <div class="row">
              <div class="col-lg-6">
                <span class="badge bg-secondary">Titulo</span>
                <input type="text" class="mt-1 form-control" required v-model="etitulo">
              </div>
              <div class="col-lg-6">
                <span class="badge bg-secondary">Titulo Inglés</span>
                <input type="text" class="mt-1 form-control" required v-model="etitulo_eng">
              </div>
            </div> <br>

            <div class="row">
              <div class="col-lg-6">
                <span class="badge bg-secondary">Resumen</span>
                <textarea rows="3" class="mt-1 form-control" required v-model="eresumen" />
              </div>
              <div class="col-lg-6">
                <span class="badge bg-secondary">Resumen Ing</span>
                <textarea rows="3" class="mt-1 form-control" required v-model="eresumen_eng" />
              </div>
            </div> <br>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal2.hide()">Cerrar</button>
          <button type="submit" class="btn btn-primary">Editar</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal Editar Foto-->
<div class="modal fade" ref="modalFoto" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar Foto</h5>
        <button type="button" class="btn-close" @click="modal3.hide()" aria-label="Close"></button>
      </div>
      <form @submit="editar_foto_upload">
        <div class="modal-body">
          <div class="container-fluid">
          
            <div class="row">
              <div class="col-lg-6">
                <span class="badge bg-secondary">Nueva Img</span>
                <input type="file" class="mt-1 form-control" required @change="addFile"> 
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal3.hide()">Cerrar</button>
          <button type="submit" class="btn btn-primary">Editar</button>
        </div>
      </form>
    </div>
  </div>
</div>

</div>
</template>


<script>
import Menu from '@/components/Menu'
import { Modal } from 'bootstrap'
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'

export default {
name: 'Integrantes',
mixins: [ globalFunctions ],
components:{ Menu },
data () {
  return {
    idioma: localStorage.idioma,
    modal: null,
    modal2: null,
    modal3: null,
    showAdmin: '',
    galeria: '',
    ultima_entrada: '',
    fecha: '',
    titulo: '',
    titulo_eng: '',
    descr: '',
    descr_eng: '',
    resumen: '',
    resumen_eng: '',
    etitulo: '',
    etitulo_eng: '',
    eresumen: '',
    eresumen_eng: '',
    efecha: '',
    fileName : '',
    url_img: '',
    id_galeria: '',
    leermas: 'Leer Más'
  }
},
computed:{
  ...mapState(['url'])
},
methods: {
  getData(){
    let data = {
      flag: 0
    }

    this.axios.post(this.url + "getGaleria.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data != null){
          this.galeria = responce.data;
        } else {
          this.galeria = [];
        }
        
    });

    let data2 = {
      flag: 1
    }

    this.axios.post(this.url + "getGaleria.php", data2).then((responce) => {
        //console.log(responce.data);
        if(responce.data != null){
          this.ultima_entrada = responce.data;
        } else {
          this.ultima_entrada = [];
        }
    });
  },
  
  altaImg(e){
    e.preventDefault();
    
    if(this.selectedFile != null){
        const fd = new FormData();
        fd.append('file',this.selectedFile, this.selectedFile.name);
        
        this.axios.post(this.url + 'uploadNew.php', fd, {header:{'Content-Type': 'multipart/form-data'}}).then((responce) =>{
          //console.log(responce);
          if(responce.data.res == '200'){
            let file = responce.data.nombre;
            this.alta(file);
          }
        })
    };
  },

  alta(file){

    let data = {
      flag: 0,
      fecha: this.fecha,
      titulo: this.titulo,
      titulo_eng: this.titulo_eng,
      descr: this.descr,
      descr_eng: this.descr_eng,
      resumen: this.resumen,
      resumen_eng: this.resumen_eng,
      file
    }
    
    this.axios.post(this.url + "altaGaleria.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.modal.hide();
          this.getData();
          this.msjOk("Galeria Insertada con éxito");
          this.fecha = "";
          this.titulo = "";
          this.titulo_eng = "";
          this.descr = "";
          this.descr_eng = "";
          this.resumen = "";
          this.resumen_eng = "";
        }
    });

  },

  addFile(event){
    this.selectedFile = event.target.files[0];
    this.fileName = this.selectedFile.name;
  },

  borrar(f){
    
    let data = {
      flag: 3,
      id: f.id_galeria,
      file: f.url_img,
    }
    
    this.axios.post(this.url + "altaGaleria.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.getData();
          this.msjOk("Galeria Borrada con éxito");
        }
    });
  },

  editar_foto_upload(e){
    e.preventDefault();

    if(this.selectedFile != null){
        const fd = new FormData();
        fd.append('file',this.selectedFile, this.selectedFile.name);
        
        this.axios.post(this.url + 'uploadNew.php', fd, {header:{'Content-Type': 'multipart/form-data'}}).then((responce) =>{
          //console.log(responce);
          if(responce.data.res == '200'){
            let file = responce.data.nombre;
            this.editar_foto_alta(file);
          }
        })
    };
  },

  editar_foto_alta(file){
    let data = {
      flag: 4,
      id: this.id_galeria,
      ex_img: this.ex_img,
      file
    }
    
    this.axios.post(this.url + "altaGaleria.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.modal3.hide();
          this.getData();
          this.msjOk();
        }
    });
  },

  showModalEdicionFoto(f){
    this.modal3.show();
    this.id_galeria = f.id_galeria;
    this.ex_img = f.url_img;
  },

  editar(e){
    e.preventDefault();

    let data = {
      flag: 5,
      titulo: this.etitulo,
      titulo_eng: this.etitulo_eng,
      resumen: this.eresumen,
      resumen_eng: this.eresumen_eng,
      fecha: this.efecha,
      id: this.id_galeria
    }

    this.axios.post(this.url + "altaGaleria.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.modal2.hide();
          this.getData();
          this.msjOk();
        }
    });

  },

  showModalEdicion(f){
    //console.log(f);
    this.modal2.show();
    
    this.etitulo = f.titulo;
    this.etitulo_eng = f.titulo_eng;
    this.eresumen = f.resumen;
    this.eresumen_eng = f.resumen_eng;
    this.efecha = f.fecha_galeria;
    this.id_galeria = f.id_galeria;
  },

  getEnglish(){
    this.leermas = 'Read more';
  },
  terminar(){
    this.showAdmin = false;
    this.setInit();
  },
  checkToken(){
      const token = localStorage.token;
  
      let data = {
          token: token,
      }

      this.axios.post(this.url + "checkToken.php", data).then((responce) => {
          if(responce.data == 200){
              this.showAdmin = true;
          }else{
              this.showAdmin = false;
          }
      });
            
  }
},
mounted(){
  this.modal = new Modal(this.$refs.exampleModal);
  this.modal2 = new Modal(this.$refs.editarModal)
  this.modal3 = new Modal(this.$refs.modalFoto);
  
  this.checkToken();

  window.scrollTo(0, 0);
  this.getData();
  
  if(this.idioma == 'EN'){
    this.getEnglish();
  }
}
}
</script>


<style scoped>

.cuerpo{
  background:/* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.7), 
          rgba(0, 0, 0, 0.7)
        ),
        url('../assets/osa_convocatorias_cover.jpg');
    background-size: cover;
    position: relative;
    min-height: 80vh;
}

.txt_ultima_entrada{
  position:relative; 
  margin-top: 10%;
}

.img{
  position: relative;
  width: 85%;
  /*height: 451px;
  object-fit: cover;*/

  height: auto;
  object-fit: cover;
  aspect-ratio:  52 / 35;
}

.cuerpo_ultima_entreda{
  padding-left: 8%;
  padding-right: 8%;
}

.cuerpo-galerias{
  position: relative;
  /*width: 1000%;*/
  /*padding-left: 28px;*/
}

.txt_001{
  position:relative; 
  top:-20px
}

.txt_000{
  line-height: 72px;
}

/* Medium-small devices (phones, 900px and down) */
@media only screen and (max-width: 980px) {
  
  .img{
    width: 93%;
  }
  
  .txt_ultima_entrada{
    position:relative; 
    margin-top: 0%;
  }

  .cuerpo_ultima_entreda{
    padding-left: 50px;
  }

}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 700px) {
  
  .img{
    width: 96%;
    position: relative;
    margin-left: 2%;
    margin-right: 2%;
  }

  .cuerpo_ultima_entreda{
    padding-left: 0px;
  }

  .txt_000{
    line-height: 35px;
  }

  .txt_001{
    top: -5px;
    font-size: 22px !important;
  }
}

</style>