<template>
<div>

  <!--Menu Admin -->
  <div class="menuAdmin container" v-if="showAdmin == true">
    <div class="row p-3">
      <div class="col-sm-6">
        <button type="button" class="m-1 btn btn-sm btn-primary" @click="modal.show()">
         Editar Contenido
        </button>
      </div>
      <div class="col-sm-6">
        <button class="btn btn-danger float-end btn-sm" @click="terminar()">Terminar</button>
      </div>
    </div>
  </div>

  <div>
      <Menu :type='404'/>
  </div>


  <div class="separador-chico"></div>

  <div class="container-fluid" v-for="f in cuerpo" :key="f.id_convocatoria">
    <div class="cuerpo pb-7">
      <p class="p-lg-5 p-4">
        <router-link to="/Convocatorias" class="link texto-normal-bold s18" style="color:black" >
            <i class="fas fa-less-than amarillo"></i> &nbsp;&nbsp; 
            <span v-if="idioma == 'ES'">Convocatorias</span>
            <span v-else>Announcements</span>
        </router-link>
      </p>

      <div class="row">
        <div class="col-sm-12">
          <p class="text-center texto-normal-bold s16 upper" style="line-height:100%;letter: spacing 0.02em;">
            <span style="text-transform: capitalize;"> {{getDiaNombre(f.fecha)}} </span> 
            {{getDia(f.fecha)}} 
            <span style="text-transform: lowercase;"> {{getMes(f.fecha)}} </span>, 
            {{getAno(f.fecha)}}
          </p>
          <p class="text-center texto-times-bold pb-5 pt-5 s60 img_principal" style="line-height:100%">  
            <span v-if="idioma == 'ES'"> {{f.titulo}} </span>
            <span v-else> {{f.titulo_eng}} </span>  
          </p>

          <hr class="img_principal" style="border: 2px solid;">

          <div class="section_img_principal">
            <img :src="url + 'uploads/' + f.url_img" class="img_principal">
          </div>
          
          <p class="texto-normal pt-5 pb-5 s20 padding-both-sides" style="line-height: 160%; white-space:pre-wrap;">
            <span v-if="idioma == 'ES'"> {{f.resumen}} </span>
            <span v-else>{{f.resumen_eng}}</span>
          </p>
        </div>
      </div>

      
    </div>
  </div>

  <div class="separador"></div>

<!-- Modal Alta -->
<div class="modal fade" ref="exampleModal" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar Contenido</h5>
        <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
      </div>
      <form @submit="alta">
        <div class="modal-body">
          <div class="container-fluid">

            <div class="row pt-2">
              <div class="col-sm-12">
                <span class="badge bg-secondary">Cuerpo</span>
                <textarea rows="5" class="mt-1 form-control" required v-model="resumen" />
              </div>
            </div>

             <div class="row pt-2">
              <div class="col-sm-12">
                <span class="badge bg-secondary">Cuerpo Inglés</span>
                <textarea rows="5" class="mt-1 form-control" required v-model="resumen_eng" />
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal.hide()">Cerrar</button>
          <button type="submit" class="btn btn-primary">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</div>

</div>
</template>


<script>
import Menu from '@/components/Menu'
import { Modal } from 'bootstrap'
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'

export default {
name: 'Integrantes',
mixins: [ globalFunctions ],
components:{ Menu },
computed:{
  ...mapState(['url'])
},
data () {
  return {
    idioma: localStorage.idioma,
    modal: null,
    modal2: null,
    showAdmin: '',
    id: this.$route.params.id,
    cuerpo: '',
    resumen: '',
    resumen_eng: ''
  }
},
methods: {
  getData(){
     
      let data = {
        flag: 2,
        id: this.id,
      }

      this.axios.post(this.url + "getConvocatoria.php", data).then((responce) => {
          //console.log(responce.data);
          this.cuerpo = responce.data;
          this.resumen = responce.data[0].resumen;
          this.resumen_eng = responce.data[0].resumen_eng;
      });
  },

  alta(e){
     e.preventDefault();

    let data = {
      flag: 1,
      id: this.id,
      cuerpo: this.resumen,
      cuerpo_eng : this.resumen_eng
    }

    this.axios.post(this.url + "altaConvocatoria.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.modal.hide();
          this.getData();
          this.cuerpo = "";
          this.cuerpo_eng = "";
          this.msjOk("Cuerpo actualizado con éxito");
        }
    });
  },
  
  getEnglish(){

  },
  
  terminar(){
    this.showAdmin = false;
    this.setInit();
  },

  checkToken(){
      const token = localStorage.token;
  
      let data = {
          token: token,
      }

      this.axios.post(this.url + "checkToken.php", data).then((responce) => {
          if(responce.data == 200){
              this.showAdmin = true;
          }else{
              this.showAdmin = false;
          }
      });
            
  }
},
mounted(){
  this.modal = new Modal(this.$refs.exampleModal)
  //this.modal2 = new Modal(this.$refs.staticBackdrop)
  
  this.checkToken();

  window.scrollTo(0, 0);
  this.getData();
  
  if(this.idioma == 'EN'){
    this.getEnglish();
  }
}
}
</script>


<style scoped>

.cuerpo{
    background-color: white;
    margin-left: 8%;
    margin-right: 8%;
}

hr{
  color: #e6bd07;
}

.img{
  position: relative;
  width: 640px;
  height: 451px;
  float: right;
}

.padding-both-sides{
  padding-left: 15%;
  padding-right: 15%;
}

.section_img_principal{
  width: 100%;
  padding-top: 50px;
}

.img_principal{
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


/* Medium-small devices (phones, 900px and down) */
@media only screen and (max-width: 980px) {
  

}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 700px) {
  
  .img_principal{
    width: 90%;
  }

  .padding-both-sides{
    padding-left: 2%;
    padding-right: 2%;
  }

  .cuerpo{
    margin-left: 1%;
    margin-right: 1%;
}

}
</style>