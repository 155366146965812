<template>
  
  <div>
    <div id="router-view">
      <router-view/>
    </div>

    <Footer />
  </div>

</template>

<script>
import Footer from '../src/components/Footer'
export default {
components:{Footer},
name: 'App',

data(){
  return { }
},

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

#app{
  background-color: #111214;
  font-weight: normal;
}

@font-face {
  font-family: "Butler";
  src: url('../src/assets/fonts/Butler_ExtraBold.otf');
}

@font-face {
  font-family: "HKGrotesk";
  src: url('../src/assets/fonts/HKGrotesk-Black.otf');
}

@font-face {
  font-family: "HKGrotesk-regular";
  src: url('../src/assets/fonts/HKGrotesk-Regular.otf');
}

@font-face {
  font-family: "Playfair-bold";
  src: url('../src/assets/fonts/PlayfairDisplay-Bold.ttf');
}

@font-face {
  font-family: "Playfair-bold-italic";
  src: url('../src/assets/fonts/PlayfairDisplay-BoldItalic.ttf');
}

@font-face {
  font-family: "Playfair-italic";
  src: url('../src/assets/fonts/PlayfairDisplay-Italic.ttf');
}

@font-face {
  font-family: "Playfair-regular";
  src: url('../src/assets/fonts/PlayfairDisplay-Regular.ttf');
}

#router-view{
  position: relative;
  /*min-height: 100vh;*/
}

.amarillo{
  color:#daa23f;
}

.bg-black{
  background-color: #0C0D0E;
}

.pointer{
  cursor: pointer;
}

.link{
    text-decoration: none !important;
    cursor: pointer;
}

.link:hover{
  text-decoration: none !important;
  color: whitesmoke;
  transition: 2s;
}

.padding{
  padding-left: 8% !important;
}

.padding-all{
  padding-top: 0px;
  /*padding-bottom: 170px;*/
  padding-left: 8%;
  padding-right: 8%;
}

.space{
    padding-left: 10px;
    padding-right: 10px;
}

.main-color{
  background-color: #00c3a5 !important;
}

.white{
  color: #ebebeb;
}

.img-concierto_001{
    height: auto;
    width: 100%;
    object-fit: cover;
    aspect-ratio:  52 / 35;
}

.img-concierto_001:hover{
  /*-webkit-filter: brightness(70%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;*/
  filter: brightness(70%);
  transition: 1s;
}

.href-chica{
    font-family: 'Playfair-italic', sans-serif;
    color: white;
    border-bottom: solid 3px #daa23f;
    font-weight: 100;
    padding-bottom: 8px;
    text-decoration: none;
}

.link_001:hover i{
    background-color: #daa23f;
    transition: 1s;
}

.href-chica:hover{
  color: #daa23f;
  transition: 1s;
}

.href-chica-white{
    font-family: 'Playfair-italic', sans-serif;
    font-size: 20px;
    color: black;
    border-bottom: solid 3px #daa23f;
    font-weight: 700;
    text-decoration: none;
}

.href-chica-white:hover{
  color: #daa23f;
  transition: 1s;
}

.arrow{
  border: 2px solid #daa23f;;
  border-radius: 25px;
  padding: 15px;
  color: white;
}

.botonMas{
  width: 130px !important;
  height: 130px !important;
  background-color:#daa23f;
  color: black;
  font-family: 'Playfair-bold-italic', sans-serif;
  border-radius: 50%;
  display: inline-block;
  font-weight: 700 !important;
  font-size: 20px;
  text-decoration: none !important;
  cursor: pointer;
}

.botonMas:hover{
  transition: 2s;
  background-color: #ad7a21;
  color: black;
}

.textMas{
    position: relative;
    top: 40px;
}

.boton-enviar{
  background-color: #daa23f;
  padding: 15px;
  border: 0px;
  letter-spacing: 0.065em;
}

.img-concierto{
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.img-concierto2{
  height: 227px;
  width: 341px;
}

.section{
  position: relative;
  padding-left: 50px !important;
}

.pt-6{
  padding-top: 60px;
}

.pt-7{
  padding-top: 120px;
}

.pb-6{
  padding-bottom: 60px;
}

.pb-7{
  padding-bottom: 170px;
}

.separador{
  /*border: 1px solid red;*/
  height: 260px;
}

.separador-chico{
  /*border: 1px solid red;*/
  height: 100px;
}

.upper{
  text-transform: uppercase;
}

.concierto-body{
    padding: 40px;
    margin-left: 7%;
    margin-right: 7%;
    background-color: #111214;
    border: 2px solid #111214;
}

.concierto-body:hover{
    border: 2px solid #daa23f;
    transition: 1s;
}

.concierto-body:hover h5{
    color: #daa23f;
    transition: 1s;
}

.fecha_sm{
  display: none;
}

.fecha_lg {
  display: inline;
}

.let_sp{
  letter-spacing: 2px !important;
}

.f_dia{
  position: relative;
  top: 8px;
}

.f_mes{
  position: relative;
  top: -10px;
}

.titulo_times{
  font-family: 'Playfair-bold', sans-serif;
  font-size: 57.15px;
  font-weight: 700;
}

.titulo1{
    font-family: 'Playfair-italic', sans-serif;
    color: white;
    letter-spacing: 0.02em;
}

.titulo2{
    font-family: 'HKGrotesk', sans-serif;
    font-weight: 900;
    color: white;
}

.texto-yellow{
  color: #e4bd77;
}

.texto-butler{
  font-family: 'Butler', sans-serif;
}

.texto-times{
  font-family: 'Playfair-regular', sans-serif;
}

.texto-times-bold{
  font-family: 'Playfair-bold', sans-serif;
}

.texto-times-bold-italic {
  font-family: 'Playfair-bold-italic', sans-serif;
}

.texto-times-italic {
  font-family: 'Playfair-italic', sans-serif;
}

.texto-normal{
  font-family: 'HKGrotesk-regular', sans-serif;
}

.texto-normal-bold{
  font-family: 'HKGrotesk', sans-serif;
}

.s12{
  font-size: 12px;
}

.s14{
  font-size: 14px
}

.s16{
  font-size: 16px;
}

.s18{
  font-size: 18px;
}

.s20{
  font-size: 20px;
}

.s22{
  font-size: 22px;
}

.s23{
  font-size: 23px;
}

.s24{
  font-size: 24px;
}

.s26{
  font-size: 26px;
}

.s28{
  font-size: 28px;
}

.s30{
  font-size: 30px;
}

.s36{
  font-size: 36px;
}

.s38{
  font-size: 38px;
}

.s43{
  font-size: 43px;
}

.s56{
  font-size: 56px;
}

.s60{
  /*font-size: 60px;*/
  font-size: 52px;
}

.s80{
  font-size: 80px;
}

.s100{
  font-size: 100px;
}

.fw800{
  font-weight: 800;
}

.fw400{
  font-weight: 400;
}

.fw900{
  font-weight: 900;
}

.ls16{
  letter-spacing: 0.16em;
}

/* Medium-small devices (tablets, 900px and down) */
@media only screen and (max-width: 990px) {

 .padding{
    padding-left: 3% !important;
  }

  .pb-7{
    padding-bottom: 100px;
  }

  .s80{
    font-size: 60px;
  }

  .s26{
    font-size: 20px;
  }

  .s60{
    font-size: 40px;
  }

  .fecha_sm{
    display: table;
  }

  .fecha_lg {
    display: none;
  }

  .concierto-body{
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 2%;
    margin-right: 0%;
    margin-bottom: 50px;
    background-color: #111214;
  }

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  
  .padding{
    padding-left: 0% !important;
  }

  .pb-7{
    padding-bottom: 60px;
  }

  .botonMas{
    width: 90px !important;
    height: 90px !important;
    display: inline-block;
    font-weight: 700 !important;
    font-size: 14px;
  }

  .fecha_sm{
    margin-top: 80px !important;
    margin-bottom: 30px !important;
  }

  .concierto-body{
    margin-top: 12px;
    padding-bottom: 50px;
    background-color: #111214;
  }

  .textMas{
    top: 25px;
  }

  .arrow{
    padding: 10px;
    font-size: 12px;
  }

  .s60{
    font-size: 30px;
  }
  
  .s43{
    font-size: 30px;
  }

  .s80{
    font-size: 40px;
  }

  .s38{
    font-size: 28px;
  }

  .s23{
    font-size: 14px;
  }

  .titulo_times{
    font-size: 40px;
  }

  .let_sp{
    letter-spacing: 0px !important;
  }

}
</style>
