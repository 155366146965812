<template>
<div>
    
  <div>
        <Menu :type='404'/>
  </div>


<div class="separador-chico"></div>

<div class="container-fluid">
    <div class="cuerpo_int pb-7">
      <p class="p-lg-5 p-4">
        <router-link to="/Integrantes" class="link texto-normal-bold s18" style="color:black" >
          <i class="fas fa-less-than amarillo"></i> &nbsp;&nbsp;
          <span v-if="idioma == 'ES'">Integrantes</span>
          <span v-else>Members</span>
        </router-link>
      </p>

      <p class="text-center texto-times-italic s20" style="line-height:100%">
        <span v-if="idioma == 'ES'">{{titulo}}</span>
        <span v-else>{{titulo_eng}}</span>
      </p>
      <p class="text-center texto-times-bold pb-5 s80" style="line-height:86%">{{nombre}} <br> {{apellido}} </p>

      <div class="section_img_principal">
        <img :src="url + 'uploads/' + img" class="img_principal">
      </div>
      
      <p class="texto-normal pt-5 s20 padding-both-sides" style="line-height: 160%;white-space:pre-wrap;">
        <span v-if="idioma == 'ES'">{{detalle}}</span>
        <span v-else>{{detalle_eng}}</span>
      </p>
    </div>
</div>

<div class="separador"></div>

</div>
</template>


<script>
import Menu from '@/components/Menu'
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'

export default {
name: 'DetalleIntegrantes',
mixins: [ globalFunctions ],
components:{ Menu },
computed:{
    ...mapState(['url'])
},
data () {
  return {
    id: this.$route.params.id,
    idioma: localStorage.idioma,
    titulo: '',
    titulo_eng: '',
    nombre: '',
    apellido: '',
    detalle: '',
    detalle_eng: '',
    img: ''
  }
},
methods: {
  getData(){
    let data = {
        flag: 3,
        id: this.id
    }
    
    this.axios.post(this.url + "getIntegrantes.php", data).then((responce) => {
        //console.log(responce);
        if(responce.status == 200){
          this.titulo = responce.data[0].nombre_ins;
          this.titulo_eng = responce.data[0].nombre_ins_eng;
          this.nombre = responce.data[0].nombre;
          this.apellido = responce.data[0].apellido;
          this.detalle = responce.data[0].descr;
          this.detalle_eng = responce.data[0].descr_eng;
          this.img = responce.data[0].url_img;
          //console.log(this.img);
        }
    });
  }
},
mounted(){
  window.scrollTo(0, 0);
  this.getData();
}
}
</script>


<style scoped>

.cuerpo_int{
  background-color: white;
  margin-left: 8%;
  margin-right: 8%;
}

.section_img_principal{
  width: 100%;
}

.padding-both-sides{
  padding-left: 25%;
  padding-right: 25%;
}

.img_principal{
  width: auto;
  max-width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Medium-small devices (phones, 900px and down) */
@media only screen and (max-width: 980px) {
  
  .padding-both-sides{
    padding-left: 5%;
    padding-right: 5%;
  }

}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 700px) {
  
  .img_principal{
    width: 100%;
  }

  .cuerpo_int{
    margin-left: 1%;
    margin-right: 1%;
  }

}

</style>