<template>
  <div class="cuerpo">
    
    <Menu :type='404'/>
    <div class="separador-chico"></div>

    <div class="container-fluid section_contacto white">
        <div class="row">
          <div class="col-12">

            <p class="text-center texto-times-bold s20 pt-5">
              <span v-if="idioma == 'ES'"> ¡Ups! </span>
              <span v-else> Ups! </span>
            </p>

            <h2 class="pt-5 text-center texto-times-bold white s60">
              <span v-if="idioma == 'ES'"> Esta Página no existe </span>
              <span v-else> Page Not Found </span>
            </h2>

            <p class="pt-7 text-center" style="padding-bottom:80px">
              <router-link to="/" style="color:black;" class="link texto-normal-bold fw900 s12 boton-enviar">
                <span v-if="idioma == 'ES'"> Ir a Inicio </span>
                <span v-else> Return Home </span>
              </router-link>
            </p>
          </div>
        </div>
    </div>

  </div>
</template>


<script>
import Menu from '@/components/Menu'
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'

export default {
name: 'Integrantes',
components:{ Menu },
mixins: [ globalFunctions ],
computed:{
    ...mapState(['url'])
},
data () {
  return {
    idioma: localStorage.idioma,
    showAdmin: true,
    nombre: '',
    email: '',
    telefono: '',
    asunto: '',
    mensaje: ''
  }
},
methods: {
  alta(e){
    e.preventDefault();

    let data = {
      flag: 0,
      nombre: this.nombre,
      correo: this.email,
      telefono: this.telefono,
      asunto: this.asunto,
      mensaje: this.mensaje
    }

    this.axios.post(this.url + "sendEmail.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.nombre = "";
          this.email = "";
          this.telefono = "";
          this.asunto = "";
          this.mensaje = "";

          this.msjOk("Mensaje enviado con éxito");
        }
    });

  },
  checkToken(){
      const token = localStorage.token;
  
      let data = {
          token: token,
      }

      this.axios.post(this.url + "checkToken.php", data).then((responce) => {
          if(responce.data == 200){
              this.showAdmin = true;
          }else{
              this.showAdmin = false;
          }
      });
            
  }
},
mounted(){
  this.checkToken();
  window.scrollTo(0, 0);
}
}
</script>


<style scoped>
.cuerpo{
  background:/* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.7), 
          rgba(0, 0, 0, 0.7)
        ),
        url('../assets/dark.png');
    background-size: cover;
    position: relative;
    min-height: 80vh;
}

.texto-sub-amarillo{
  font-family: "HKGrotesk";
  color: white;
  border-bottom: solid 3px #daa23f;
  font-weight: 700;
  font-size: 20px;
}

.contenedor{
  border: 3px solid #daa23f;
  position: relative;
  padding: 30px;
  height: 800px;
  width: 93%;
  top: 60px;
}

.section_contacto{
  position: relative;
  padding-left: 50px;
}

.contenedor2{
  position: relative;
  padding: 40px;
}

.text_contact{
  padding-right: 150px;
}

/* Medium-small devices (phones, 900px and down) */
@media only screen and (max-width: 980px) {
  
  .contenedor{
    height: 800px;
    width: 95%;
  }

}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 700px) {
  .contenedor2{
    position: relative;
    padding: 10px;
  }

  .section_contacto{
    padding-left: 5%;
  }
  
}
</style>