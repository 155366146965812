<template>
  <div class="cuerpo">
    
    <Menu :type='404'/>
    <div class="separador-chico"></div>

    <div class="container-fluid section_contacto white">
        <div class="row">
          <div class="col-12">
            <button class="btn btn-primary m-2" @click="sendEmail">Send Email</button>
            <button class="btn btn-primary m-2" @click="checkApi">Check API</button>
          </div>
        </div>
    </div>

  </div>
</template>


<script>
import Menu from '@/components/Menu'
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'

export default {
name: 'Integrantes',
components:{ Menu },
mixins: [ globalFunctions ],
computed:{
    ...mapState(['url'])
},
data () {
  return {
    idioma: localStorage.idioma,
    showAdmin: true,
  }
},
methods: {
  checkApi(){
    
    this.axios.post(this.url + "prueba.php").then((responce) => {
        console.log(responce.data);
    });

  }
},
mounted(){
  //this.checkToken();
  window.scrollTo(0, 0);
}
}
</script>


<style scoped>
.cuerpo{
  background:/* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.7), 
          rgba(0, 0, 0, 0.7)
        ),
        url('../assets/dark.png');
    background-size: cover;
    position: relative;
    min-height: 80vh;
}

.texto-sub-amarillo{
  font-family: "HKGrotesk";
  color: white;
  border-bottom: solid 3px #daa23f;
  font-weight: 700;
  font-size: 20px;
}

.contenedor{
  border: 3px solid #daa23f;
  position: relative;
  padding: 30px;
  height: 800px;
  width: 93%;
  top: 60px;
}

.section_contacto{
  position: relative;
  padding-left: 50px;
}

.contenedor2{
  position: relative;
  padding: 40px;
}

.text_contact{
  padding-right: 150px;
}

/* Medium-small devices (phones, 900px and down) */
@media only screen and (max-width: 980px) {
  
  .contenedor{
    height: 800px;
    width: 95%;
  }

}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 700px) {
  .contenedor2{
    position: relative;
    padding: 10px;
  }

  .section_contacto{
    padding-left: 5%;
  }
  
}
</style>