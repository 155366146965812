<template>

<div class="bg-black" style="border: 1px solid #0C0D0E">

  <!--Menu Admin -->
  <div class="menuAdmin" v-if="showAdmin == true">
    <div class="row p-3">
      <div class="col-md-6">
        <button type="button" class="btn btn-sm btn-success" @click="modal.show()">
          <i class="fas fa-plus"></i> Concierto
        </button>
      </div>
      <div class="col-md-6">
        <button class="btn btn-danger float-end btn-sm" @click="terminar()">Terminar</button>
      </div>
    </div>
  </div>
  
  <!--Menu -->
  <div class="cuerpo">
      <Menu :type='1'/>
      <div class="container-fluid texto-hero pt-4">
        <div class="row padding-all">
          <div class="col-md-5 animate__animated animate__fadeIn">
            <div v-if="idioma == 'ES'">
              <span class="white texto-times-italic s30 txt_001">Calendario de </span>
              <h2 class="titulo2 padding s80">CONCIERTOS</h2>
            </div>
            <div v-else>
              <p class="white texto-times-italic s30 txt_001">Concert </p>
              <h2 class="titulo2 padding s80" style="line-height: 72px">CALENDAR</h2> 
            </div>
          </div>
        </div>
      </div>
  </div>

  <!--Calendario -->
  <div class="container-fluid">
    <div class="padding-all pb-6">
      <div class="row">
        <div class="col-md-6 col-12">
          <p class="pt-5 link_001">
            <span class="white texto-normal-bold s30 pointer" @click="getData()">{{mes}}</span>
            &nbsp;&nbsp;&nbsp;
            <i class="fas fa-arrow-down arrow pointer" style="position:relative; top:-5px" @click="getData()"></i>
          </p>
        </div>
        <div class="col-md-6 col-12">
          <div class="calendario-position calendario">
            <v-date-picker v-model="date" @click="getConcierto()" title-position="left" color="yellow" is-expanded is-dark class="date-picker" />
          </div>
        </div>
      </div>
    </div>
  </div>


  <!--Conciertos -->
  <div class="container-fluid">
    
    <div v-if="conciertos.length > 0">
      <div v-for="f in conciertos" :key="f.id_concierto">

      <div class="concierto-body">
        
        <!--Botones Admin -->
        <div class="row">
          <div class="col-md-12 pb-3" v-if="showAdmin == true">
              <button class="mx-2 btn btn-sm btn-primary" @click="showModalEdicion(f)">
                <i class="fas fa-edit"></i>
              </button>
              <button class="mx-2 btn btn-sm btn-primary" @click="showModalEdicionFoto(f)"><i class="fas fa-edit"></i> Foto</button>
              <button class="mx-2 btn btn-sm btn-danger" @click="borrar(f)">
                <i class="fas fa-trash"></i>
              </button>
          </div>
        </div>

        <div class="row" style="color:white">
          <div class="col-lg-2 col-md-12 col-12 order-md-1 order-2">
              <div class="fecha_lg">
                <p class="text-center f_dia">
                  <span class="texto-normal-bold s16 upper let_sp">{{getDiaNombre(f.fecha_concierto)}}</span>
                </p>
                <h5 class="text-center">
                  <span class="texto-butler s100"> {{getDia(f.fecha_concierto)}} </span> <br>
                </h5>
                <p class="text-center f_mes">
                  <span class="texto-normal-bold s16 upper let_sp"> {{getMes(f.fecha_concierto)}} <br> {{getAno(f.fecha_concierto)}} </span>
                </p>
              </div>
              <div class="fecha_sm">
                <p class="texto-normal-bold upper let_sp">
                  {{getDiaNombre(f.fecha_concierto)}},
                  {{getDia(f.fecha_concierto)}}
                  {{getMes(f.fecha_concierto)}} {{getAno(f.fecha_concierto)}}
                </p>
              </div>
          </div>
          <div class="col-lg-5 col-md-6 col-12 order-md-2 order-3">
              
              <router-link class="white link" :to="{name:'ConciertoDetalle',params:{id:f.id_concierto}}">
                  <h5 class="texto-times-bold s38">
                      <span v-if="idioma == 'ES'"> {{f.nombre}} </span>
                      <span v-else> {{f.nombre_eng}} </span>
                  </h5>
              </router-link>

              <span class="texto-normal-bold s16 let_sp"> <i class="fas fa-clock amarillo"></i> {{f.hora}}</span> &nbsp;&nbsp;&nbsp;
              <span class="texto-normal-bold s16 upper let_sp"> <i class="fas fa-map-marker-alt amarillo"></i> {{f.lugar}}</span>
              <p class="pt-4 texto-normal s16">
                  <span v-if="idioma == 'ES'"> {{f.descr}} </span>
                  <span v-else> {{f.descr_eng}} </span>
              </p>
              <div class="row">
                  <div class="col-md-6 col-6">
                      <p class="texto-normal-bold s14">
                          <span class="let_sp">{{txt_director}}</span> <br>
                          <span class="texto-normal">{{f.artista1}}</span>
                      </p>
                  </div>

                  <div class="col-md-6 col-6">
                      <p class="texto-normal-bold s14"> 
                          <span class="let_sp">{{txt_solista}}</span><br>
                          <span class="texto-normal"> {{f.artista2}} </span>
                      </p>
                  </div>
              </div>
              <p class="texto-normal-bold s14 let_sp">{{entrada_general}} $ {{f.precio}} </p>

              <div class="pt-4">
                  <router-link class="href-chica s18 link" :to="{name:'ConciertoDetalle',params:{id:f.id_concierto}}">{{ver_evento}}</router-link> &nbsp;&nbsp;&nbsp;
                  <a :href="f.url_ticket" class="href-chica s18 link">{{comprar_boletos}}</a>
              </div>
              
          </div>
          <div class="col-lg-5 col-md-6 col-12 order-md-3 order-1">
                <router-link class="white link" :to="{name:'ConciertoDetalle',params:{id:f.id_concierto}}">
                  <img :src="url + 'uploads/' + f.url_img" class="img-concierto">
              </router-link>
          </div>
      </div>
      </div>

      <div style="height:15px"></div>
      
      </div>
    </div>
    <div v-else>
      <p class="text-center white texto-normal-bold s30">{{txt_nohay}}</p>
    </div>

  </div>



<!-- Modal Editar -->
<div class="modal fade" ref="staticBackdrop" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar Concierto</h5>
        <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
      </div>
      <form @submit="editar">
        <div class="modal-body">
          <div class="container-fluid">
          
            <div class="row">
              <div class="col-md-6">
                <span class="badge bg-secondary">Fecha</span>
                <input type="date" class="mt-1 form-control" required v-model="efecha">
              </div>
              <div class="col-md-6">
                <span class="badge bg-secondary">Hora</span>
                <input type="text" class="mt-1 form-control" required v-model="ehora">
              </div>
            </div>


            <div class="row pt-2">
              <div class="col-md-6">
                <span class="badge bg-secondary">Nombre</span>
                <input type="text" class="mt-1 form-control" required v-model="enombre">
              </div>
              <div class="col-md-6">
                <span class="badge bg-secondary">Nombre Inglés</span>
                <input type="text" class="mt-1 form-control" required v-model="enombre_eng">
              </div>
            </div>

              <div class="row pt-2">
              <div class="col-md-6">
                <span class="badge bg-secondary">Lugar</span>
                <input type="text" class="mt-1 form-control" required v-model="elugar">
              </div>
              <div class="col-md-6">
                <span class="badge bg-secondary">Precio</span>
                <input type="text" class="mt-1 form-control" required v-model="eprecio">
              </div>
            </div>

            <div class="row pt-2">
              <div class="col-md-6">
                <span class="badge bg-secondary">Director Artistico</span>
                <input type="text" class="mt-1 form-control" required v-model="eartista1">
              </div>
              <div class="col-md-6">
                <span class="badge bg-secondary">Solista Invitado</span>
                <input type="text" class="mt-1 form-control" v-model="eartista2">
              </div>
            </div>
            
            <div class="row pt-2">
              <div class="col-md-12">
                <span class="badge bg-secondary">Descripción</span>
                <textarea class="mt-1 form-control" required v-model="edesc" rows="2"></textarea>
              </div>
            </div>

            <div class="row pt-2">
              <div class="col-md-12">
                <span class="badge bg-secondary">Descripción Inglés</span>
                <textarea class="mt-1 form-control" required v-model="edesc_eng" rows="2"></textarea>
              </div>
            </div>


          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal2.hide()">Cerrar</button>
          <button type="submit" class="btn btn-primary">Editar</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal Editar -->
<div class="modal fade" ref="modalFoto" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar Foto</h5>
        <button type="button" class="btn-close" @click="modal3.hide()" aria-label="Close"></button>
      </div>
      <form @submit="editar_foto_upload">
        <div class="modal-body">
          <div class="container-fluid">
          
            <div class="row">
              <div class="col-md-6">
                <span class="badge bg-secondary">Fecha</span>
                <input type="file" class="mt-1 form-control" required @change="addFile"> 
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal3.hide()">Cerrar</button>
          <button type="submit" class="btn btn-primary">Editar</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal Alta -->
<div class="modal fade" ref="exampleModal" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Nuevo Concierto</h5>
        <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
      </div>
      <form @submit="alta">
        <div class="modal-body">
          <div class="container-fluid">
          
            <div class="row">
              <div class="col-md-3">
                <span class="badge bg-secondary">Fecha</span>
                <input type="date" class="mt-1 form-control" required v-model="fecha">
              </div>
              <div class="col-md-3">
                 <span class="badge bg-secondary">Precio Desde</span>
                <input type="text" class="mt-1 form-control" required v-model="precio">
              </div>
              <div class="col-md-6">
                <span class="badge bg-secondary">Imagen</span>
                <input type="file" class="mt-1 form-control" required @change="addFile"> 
              </div>
            </div>


            <div class="row pt-2">
              <div class="col-md-6">
                <span class="badge bg-secondary">Nombre</span>
                <input type="text" class="mt-1 form-control" required v-model="nombre">
              </div>
              <div class="col-md-6">
                <span class="badge bg-secondary">Nombre Inglés</span>
                <input type="text" class="mt-1 form-control" required v-model="nombre_eng">
              </div>
            </div>

              <div class="row pt-2">
              <div class="col-md-6">
                <span class="badge bg-secondary">Lugar</span>
                <input type="text" class="mt-1 form-control" required v-model="lugar">
              </div>
              <div class="col-md-6">
                <span class="badge bg-secondary">Hora</span>
                <input type="text" class="mt-1 form-control" required v-model="hora">
              </div>
            </div>

            <div class="row pt-2">
              <div class="col-md-6">
                <span class="badge bg-secondary">Director Artistico</span>
                <input type="text" class="mt-1 form-control" required v-model="artista1">
              </div>
              <div class="col-md-6">
                <span class="badge bg-secondary">Solista Invitado</span>
                <input type="text" class="mt-1 form-control" v-model="artista2">
              </div>
            </div>

             <div class="row pt-2">
              <div class="col-md-12">
                <span class="badge bg-secondary">URL Ticket</span>
                <input type="text" class="mt-1 form-control" required v-model="url_ticket">
              </div>
            </div>
            
            <div class="row pt-2">
              <div class="col-md-12">
                <span class="badge bg-secondary">Descripción</span>
                <textarea class="mt-1 form-control" required v-model="desc" rows="2"></textarea>
              </div>
            </div>

            <div class="row pt-2">
              <div class="col-md-12">
                <span class="badge bg-secondary">Descripción Inglés</span>
                <textarea class="mt-1 form-control" required v-model="desc_eng" rows="2"></textarea>
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal.hide()">Cerrar</button>
          <button type="submit" class="btn btn-success">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</div>


</div>

</template>


<script>
import Menu from '@/components/Menu'
import { Modal } from 'bootstrap'
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'

export default {
mixins: [ globalFunctions ],
name: 'Conciertos',
components:{ Menu },
data () {
  return {
    idioma: localStorage.idioma,
    modal: null,
    modal2: null,
    modal3:  null,
    showAdmin: '',
    date: '',
    fecha: '',
    artista1: '',
    artista2:'',
    hora: '',
    nombre: '',
    lugar: '',
    desc: '',
    conciertos: [],
    nombre_eng: '',
    desc_eng: '',
    precio: '',
    selectedFile: null,
    fileName : '',
    url_img: '',
    url_ticket: '',
    /*****EDICION***/
    efecha: '',
    eartista1: '',
    eartista2:'',
    ehora: '',
    enombre: '',
    elugar: '',
    edesc: '',
    enombre_eng: '',
    edesc_eng: '',
    eprecio: 0,
    id_concierto: '',
    /***TEXTO TRADUCCION***/
    txt_director: 'DIRECTOR ARTISTICO',
    mes: 'Ver Todos Los Conciertos',
    entrada_general: 'PRECIO DESDE',
    ver_evento: 'Ver Evento',
    comprar_boletos: 'Comprar Boletos',
    txt_solista: 'CON',
    txt_nohay: 'No se encontraron conciertos para esta fecha',
  }
},
computed:{
    ...mapState(['url'])
},
methods: {
  getData(){
    this.date = '';

    let data = {
      id: 1,
      flag: 0
    }

    this.axios.post(this.url + "getConcierto.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data != null){
          this.conciertos = responce.data;
        }else{
          this.conciertos = [];
        }
    });

  },

  getConcierto(){
    
    let data = {
      flag: 3,
      date: this.date
    }

    this.axios.post(this.url + "getConcierto.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data != null){
          this.conciertos = responce.data;
        }else {
          this.conciertos = '';
        }
    });
  },

  showModalEdicion(f){
    this.modal2.show();
    this.catchData(f)
  },

  showModalEdicionFoto(f){
    this.modal3.show();
    this.catchData(f)
  },
  
  catchData(f){
    
    this.id_concierto = f.id_concierto;
    this.efecha = f.fecha_concierto;
    this.eartista1 = f.artista1;
    this.eartista2 = f.artista2;
    this.ehora = f.hora;
    this.enombre = f.nombre;
    this.enombre_eng = f.nombre_eng
    this.elugar = f.lugar;
    this.edesc = f.descr;
    this.edesc_eng = f.descr_eng;
    this.eprecio = f.precio;
  },

  borrar(f){
    
    let data = {
      id: f.id_concierto,
      flag: 1,
      file: f.url_img
    }
    
    this.axios.post(this.url + "altaConcierto.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.getData();
          this.msjOk("Concierto Borrado con éxito");
        }
    });
  },

  addFile(event){
    this.selectedFile = event.target.files[0];
    this.fileName = this.selectedFile.name;
  },

  terminar(){
    this.showAdmin = false;
    this.setInit();
  },
  
  getEnglish(){
    this.txt_director = 'ART DIRECTOR';
    this.txt_solista = 'FEATURING';
    this.mes = 'See All Concerts';
    this.entrada_general = 'PRICE STARTS FROM';
    this.ver_evento = 'See Event';
    this.comprar_boletos = 'Buy Tickets';
    this.txt_nohay = 'No concerts found for this date';
  },

  clean(){
      this.fecha = "";
      this.hora = "";
      this.nombre = "";
      this.nombre_eng = "";
      this.lugar = "";
      this.desc = "";
      this.desc_eng = "";
      this.hora = "";
      this.artista1 = "";
      this.artista2 = "";
      this.precio = 0;
      this.url_ticket = "";
  },
  altaInfo(file){
    
    let data = {
      flag: 0,
      fecha: this.fecha,
      hora: this.hora,
      nombre: this.nombre,
      nombre_eng: this.nombre_eng,
      lugar: this.lugar,
      descr: this.desc,
      descr_eng: this.desc_eng,
      hora: this.hora,
      artista1: this.artista1,
      artista2: this.artista2,
      precio: this.precio,
      url_ticket: this.url_ticket,
      file
    }

    var valid_date = this.checkDate(data.fecha);
    
    if (valid_date == 1){
      this.msjError("Fecha debe ser mayor a hoy");
      return;
    }

    this.axios.post(this.url + "altaConcierto.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.clean();
          this.modal.hide();
          this.getData();
          this.msjOk("Concierto Insertado con éxito");
        }
    });
  },

  alta(e){
    e.preventDefault();
    
    if(this.selectedFile != null){
        const fd = new FormData();
        fd.append('file',this.selectedFile, this.selectedFile.name);
        
        this.axios.post(this.url + 'uploadNew.php', fd, {header:{'Content-Type': 'multipart/form-data'}}).then((responce) =>{
          //console.log(responce);
          if(responce.data.res == '200'){
            let file = responce.data.nombre;
            this.altaInfo(file);
          }
        })
    };

  },
  editar(e){
    e.preventDefault();

    let data = {
      flag: 2,
      fecha: this.efecha,
      hora: this.ehora,
      nombre: this.enombre,
      nombre_eng: this.enombre_eng,
      lugar: this.elugar,
      descr: this.edesc,
      descr_eng: this.edesc_eng,
      hora: this.ehora,
      artista1: this.eartista1,
      artista2: this.eartista2,
      precio: this.eprecio,
      id: this.id_concierto,
    }

    this.axios.post(this.url + "altaConcierto.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.clean();
          this.modal2.hide();
          this.getData();
          this.msjOk();
        }
    });

  },

  editar_foto_upload(e){
    e.preventDefault();

    if(this.selectedFile != null){
        const fd = new FormData();
        fd.append('file',this.selectedFile, this.selectedFile.name);
        
        this.axios.post(this.url + 'uploadNew.php', fd, {header:{'Content-Type': 'multipart/form-data'}}).then((responce) =>{
          //console.log(responce);
          if(responce.data.res == '200'){
            let file = responce.data.nombre;
            this.editar_foto_alta(file);
          }
        })
    };
  },

  editar_foto_alta(file){
     let data = {
      flag: 5,
      id: this.id_concierto,
      file
    }
    
    this.axios.post(this.url + "altaConcierto.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.modal3.hide();
          this.getData();
          this.msjOk();
        }
    });
  },

  checkToken(){
      const token = localStorage.token;
  
      let data = {
          token: token,
      }

      this.axios.post(this.url + "checkToken.php", data).then((responce) => {
          if(responce.data == 200){
              this.showAdmin = true;
          }else{
              this.showAdmin = false;
          }
      });
            
  }
},
mounted(){
  this.modal = new Modal(this.$refs.exampleModal)
  this.modal2 = new Modal(this.$refs.staticBackdrop)
  this.modal3 = new Modal(this.$refs.modalFoto)
  
  this.checkToken();

  window.scrollTo(0, 0);
  this.getData();
  
  if(this.idioma == 'EN'){
    this.getEnglish();
  }
}
}
</script>


<style scoped>

.cuerpo{
   background:/* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.7), 
          rgba(0, 0, 0, 0.7)
        ),
        url('../assets/osa_calendario_cover.jpg');
    background-size: cover;
    position: relative;
    min-height: 80vh;
}

.calendario-position{
  position: relative;
  top: -100px;
}

.calendario{
  position: relative;
  width: 300px;
  height: 300px;
  margin-left: 35%;
}

.txt_mes{
  padding-left: 15%;
}

.txt_001{
  /*line-height: 72px*/
  margin-bottom: 0.3rem;
}

/* Medium-small devices (phones, 900px and down) */
@media only screen and (max-width: 900px) {

  .txt_mes{
    padding-left: 0%;
  }

  .calendario{
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: 0px;
    padding-top: 10% !important;
  }

  .calendario-position{
    position: relative;
    top: 0px;
  }

}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 700px) {

}

</style>