<template>
<div class="login">
    <!--<Menu />-->
    <div class="body container">
        <div class="fadeInDown">
            <div id="formContent">
                
                <div class="row">
                  <div class="offset-sm-0 col-sm-12 offset-md-4 col-md-4">
                  
                  <form class="pb-5" @submit="login">
                    <!-- Titulo -->
                    <div class="pt-5 fadeIn first">
                        <h2 class="text-center" style="color:white">¡Inicia sesión!</h2>
                    </div> <br><br>
                    
                    <input type="text" class="form-control fadeIn second" v-model="id" required placeholder="Usuario"> <br>
                    <input type="password" class="form-control fadeIn second" v-model="pass" required placeholder="Contraseña"> <br>
                    <p class="text-center fadeIn third">
                      <button class="botonEntrar btn btn-primary" v-if="!loading"> Entrar </button>
                      <button class="botonEntrar btn btn-primary" v-else> Validando... </button>
                    </p>

                    <p class="text-center pt-5" style="color:red" v-show="msgerror">Usuario/Contraseña Incorrecto</p>
                  </form>
                  
                  </div>
                </div>

            </div>
        </div>
    </div>
</div>
</template>



<script>
import { mapState } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      id:'Admin',
      pass:'1234',
      msgerror: false,
      loading: false,
    }
  },
  computed:{
    ...mapState(['url'])
  },
  methods:{
    login(e){
        
        e.preventDefault();
        
        let data = {
          pass: this.pass,
          usuario:this.id,
          token:''
        };

        this.loading = true;

        this.axios.post(this.url + 'sesion.php', data).then((responce) =>{
          //console.log(responce.data);
          if(responce.data.res == '200')
          {
            localStorage.token = responce.data.token;
            localStorage.admin = 'true';

            this.loading = false;
            this.$router.push('/');
          }
          else {
            this.msgerror = true;
            this.loading = false;
          }
        });
    }
  },
}
</script>


<style scoped>

/* ANIMATIONS */

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fadeIn {
  opacity:0;
  -webkit-animation:fadeIn ease-in 1;
  -moz-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

  -webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  animation-duration:1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after{
  width: 100%;
}

/* OTHERS */

*:focus {
    outline: none;
} 

#icon {
  width:60%;
}


</style>>

