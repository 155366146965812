<template>
  <div class="cuerpo">
    
    <Menu :type='6'/>
    <div class="separador-chico"></div>

    <div class="container-fluid white">
      <div class="padding-all">  
        <div class="row">
          <div class="col-lg-6">
            <h2 class="texto-normal-bold upper fw900 s80 animate__animated animate__fadeIn">
              <span v-if="idioma=='ES'">contacto</span>
              <span v-else>contact</span>
            </h2>
            <div class="text_contact">
              <p class="texto-normal s20" style="line-height:160%">
                <span v-if="idioma == 'ES'">
                  ¡Queremos escucharte! Escribe tu mensaje en el siguiente formulario y un miembro de nuestro equipo te responderá lo más pronto posible.
                </span>
                <span v-else>
                  We want to hear from you! Send us a message by filling the next form and a member of our team will respond as soon as possible.
                </span>
              </p>
            </div>

              <p class="pt-5">
                <span class="texto-sub-amarillo">449 999 99 99</span>
              </p>
              
              <p>
                <span class="texto-sub-amarillo">correo@osa.com.mx</span>
              </p>
            
              <div style="width:350px">
                <p class="pt-5">
                  <span class="texto-sub-amarillo upper">teatro aguascalientes</span>
                </p>
                <p class="texto-normal s20">Av. José María Chávez S/N. Jardines de la Asunción, 20270 Aguascalientes, Ags.</p>

                <p class="pt-3">
                  <span class="texto-sub-amarillo upper">sala de conciertos</span>
                </p>
                <p class="texto-normal s20">Desarrollo Especial Talleres F.F.C.C. <br> (Complejo Ferrocarrilero Tres Centurias) 20270 Aguascalientes, Ags. México</p>
              </div>
          </div>


          <div class="col-lg-6">
            <div class="contenedor">
              <div class="contenedor2">
                <form @submit="alta">
                  <label class="texto-normal-bold fw900 s14 ls16" for="">
                    <span v-if="idioma == 'ES'">NOMBRE Y APELLIDO</span>
                    <span v-else>NAME & LAST NAME</span>
                  </label>
                  <input type="text" v-model="nombre" class="form-control">

                  <label class="pt-2 texto-normal-bold fw900 s14 ls16" for="">
                    EMAIL
                  </label>
                  <input type="text" v-model="email" class="form-control">

                  <label class="pt-2 texto-normal-bold fw900 s14 ls16" for="">
                    <span v-if="idioma == 'ES'">TELÉFONO</span>
                    <span v-else>PHONE NUMBER</span>
                  </label>
                  <input type="text" v-model="telefono" class="form-control">

                  <label class="pt-2 texto-normal-bold fw900 s14 ls16" for="">
                    <span v-if="idioma == 'ES'">ASUNTO</span>
                    <span v-else>SUBJECT</span>
                  </label>
                  <input type="text" v-model="asunto" required class="form-control">

                  <label class="pt-5 texto-normal-bold fw900 s14 ls16" for="">
                    <span v-if="idioma == 'ES'">MENSAJE</span>
                    <span v-else>MESSAGE</span>
                  </label>
                  <textarea class="form-control" v-model="mensaje" required cols="30" rows="10"></textarea>
                  <br>
                  
                  <button type="submit" class="texto-normal-bold fw900 s12 boton-enviar">
                    <span v-if="idioma == 'ES'">ENVIAR MENSAJE</span>
                    <span v-else>SEND MESSAGE</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="separador"></div>

  </div>
</template>


<script>
import Menu from '@/components/Menu'
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'

export default {
name: 'Integrantes',
components:{ Menu },
mixins: [ globalFunctions ],
computed:{
    ...mapState(['url'])
},
data () {
  return {
    idioma: localStorage.idioma,
    showAdmin: true,
    nombre: '',
    email: '',
    telefono: '',
    asunto: '',
    mensaje: ''
  }
},
methods: {
  alta(e){
    e.preventDefault();

    let data = {
      flag: 0,
      nombre: this.nombre,
      correo: this.email,
      telefono: this.telefono,
      asunto: this.asunto,
      mensaje: this.mensaje
    }

    this.axios.post(this.url + "sendEmail.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.nombre = "";
          this.email = "";
          this.telefono = "";
          this.asunto = "";
          this.mensaje = "";

          this.msjOk("Mensaje enviado con éxito");
        }
    });

  },
  checkToken(){
      const token = localStorage.token;
  
      let data = {
          token: token,
      }

      this.axios.post(this.url + "checkToken.php", data).then((responce) => {
          if(responce.data == 200){
              this.showAdmin = true;
          }else{
              this.showAdmin = false;
          }
      });
            
  }
},
mounted(){
  this.checkToken();
  window.scrollTo(0, 0);
}
}
</script>


<style scoped>
.cuerpo{
  background:/* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.7), 
          rgba(0, 0, 0, 0.7)
        ),
        url('../assets/dark.png');
    background-size: cover;
    position: relative;
    min-height: 80vh;
}

.texto-sub-amarillo{
  font-family: "HKGrotesk";
  color: white;
  border-bottom: solid 3px #daa23f;
  font-weight: 700;
  font-size: 20px;
}

.contenedor{
  border: 3px solid #daa23f;
  position: relative;
  padding: 30px;
  height: 800px;
  width: 93%;
  top: 60px;
}

.section_contacto{
  position: relative;
  padding-left: 50px;
}

.contenedor2{
  position: relative;
  padding: 40px;
}

.text_contact{
  padding-right: 150px;
}

/* Medium-small devices (phones, 900px and down) */
@media only screen and (max-width: 980px) {
  
  .contenedor{
    height: 800px;
    width: 95%;
  }

}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 700px) {
  .contenedor2{
    position: relative;
    padding: 10px;
  }

  .section_contacto{
    padding-left: 5%;
  }
  
}
</style>