<template>
  <div>
    
    <div class="cuerpo">
        <Menu :type='0'/>
        <div class="texto-hero pt-5 animate__animated animate__fadeIn">
            <h3 class="titulo1 s26 text-center">
                {{somoslaosa}}
            </h3>

            <h1 class="titulo2 s60 text-center upper" v-if="idioma == 'ES'">orquesta sinfónica de <br> aguascalientes</h1>
            <h1 class="titulo2 s60 text-center upper" v-else>Aguascalientes Symphony <br> Orchestra</h1>
        
            <p class="text-center pt-4" style="color:white">
                <router-link to="Conciertos" class="botonMas" style="line-height: 17.8px !important;">
                    <span class="textMas" v-if="idioma == 'ES'">Descubrir Conciertos</span>
                    <span class="textMas" v-else >Find out<br> more </span>
                </router-link>
            </p>
        </div>
    </div>
    
    <!--Section Gobierno de AGS -->
    <div class="container pt-6 section-padding">
        
        <p class="text-center pb-5">
            <img class="img-gobierno pb-5" src="../assets/Logo_GobAgs.png" alt="">
        </p>
        
        <p class="text-center letra2" v-if="idioma == 'ES'">
            LA ORQUESTA SINFÓNICA DE AGUASCALIENTES ES UNA DE LAS AGRUPACIONES MÁS RECONOCIDAS EN EL PAÍS.
            <span class="letra1">Dinámica y precisa,</span> ES UNA <span class="letra1">casa abierta</span> DONDE CELEBRAMOS JUNTOS 
            LA <span class="letra1">música</span><br> ¡BIENVENIDOS Y BIENVENIDAS!
        </p>
        <p class="text-center letra2" v-else>
            THE AGUASCALIENTES SYMPHONY ORCHESTRA IS ONE OF THE MOST RECOGNIZED ORCHESTRAS IN THE COUNTRY.
            <span class="letra1">Dynamic and precise,</span> IT IS AN <span class="letra1">open house </span>WHERE WE CELEBRATE <span class="letra1">music</span> TOGETHER <br>
            WELCOME!
        </p>

        <p class="text-center pt-5 link_001">
            <router-link to='/Orquesta' class="href-chica s26">
               {{descubre_osa}}
            </router-link> <br><br>
            <i class="fas fa-arrow-right arrow"></i>
        </p>
    </div>

    <!--La Osa Resumen -->
    <div class="section-laosa2 pt-7" >
        <div class="container-fluid">
            <div class="row padding-all pb-7">
                <div class="col-lg-6 order-lg-1 order-md-2 order-2 laosa">
                    <div class="laosa-cuerpo-texto">
                        <h2 class="pt-lg-5 pt-md-2 titulo_times">
                            {{laosa_title}}
                        </h2>
                        <p class="pt-5 pb-5 laosa-letra">{{con_dif_sedes}}</p>
                        <p>
                            <router-link class="href-chica-white" to='Orquesta'>
                                {{descubre_osa2}}
                            </router-link>
                        </p>   
                    </div>
                </div>
                <div class="col-lg-6 order-lg-2 order-md-1 order-1 laosa-img"></div>
            </div>
        </div>
    </div>

    <!--Eventos de Temporada-->
    <div class="section-black pt-7">
        <div class="container-fluid">
            <div class="row padding-all pb-7">
                <div class="col-lg-6 col-md-6">
                    <h2 class="letra3" v-if="idioma == 'ES'">EVENTOS <span class="letra1"> de la </span> <br> TEMPORADA</h2>
                    <h2 class="letra3" v-else>EVENTS <span class="letra1"> of the </span> <br> SEASON</h2>
                </div>
                <div class="col-lg-6 col-md-6">
                     <p class="text-center pt-5 float-end link_001">
                        <router-link to='Conciertos' class="href-chica s26 link">{{ver_conciertos}}</router-link>
                        &nbsp;&nbsp;&nbsp;
                        <i class="fas fa-arrow-right arrow"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!--Eventos de Temporada Cuerpo-->
    <div class="section-black">
        <div class="container-fluid">
            <div class="row padding-all pb-7">
                <div class="col-lg-6 col-md-6 pt-4" v-for="f in conciertos" :key="f.id_concierto">
                    <div class="white cuerpo_evento" :style="{
                                                            'background-image': 
                                                            `linear-gradient(185deg,rgba(17, 19, 20, 0.22), 40%, #111214 95.83%),
                                                            url(${(url + 'uploads/' + f.url_img)})`
                                                            }">
                        <div class="mx-3 text_evento">
                            <p class="texto-normal-bold fw400 txt_fecha_concierto">
                                <span style="text-transform: capitalize;"> {{getDiaNombre(f.fecha_concierto)}} </span> 
                                {{getDia(f.fecha_concierto)}} 
                                {{getMes(f.fecha_concierto)}},
                                {{getAno(f.fecha_concierto)}}
                            </p>
                            <div class="texto_evento_inner">
                                <router-link class="white link" :to="{name:'ConciertoDetalle',params:{id:f.id_concierto}}">
                                    <h5 class="texto-times-bold txt_001">
                                        <span v-if="idioma == 'ES'"> {{f.nombre}} </span>
                                        <span v-else> {{f.nombre_eng}} </span>
                                    </h5>
                                </router-link>
                            </div>
                            
                             <div class="pt-lg-3 pt-0">
                                <router-link class="href-chica txt_002 link" :to="{name:'ConciertoDetalle',params:{id:f.id_concierto}}">{{ver_evento}}</router-link>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Proximos Eventos-->
    <div class="section-black">
        <div class="container-fluid">
            <div class="row padding-all pb-7">
                <div class="col-lg-6 col-md-6">
                    <h2 class="letra3" v-if="idioma == 'ES'" >PRÓXIMOS <br> EVENTOS</h2>
                    <h2 class="letra3" v-else >EVENTS <br> COMMING SOON</h2>
                </div>
                <div class="col-lg-6 col-md-6">
                     <p class="text-center pt-5 float-end link_001">
                        <router-link to='/Conciertos' class="href-chica s26 link">{{ver_conciertos}}</router-link>
                        &nbsp;&nbsp;&nbsp;
                        <i class="fas fa-arrow-right arrow"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!--Conciertos -->
    <div class="section-black">
        <div class="container-fluid">
            <div v-for="f in prox_conciertos" :key="f.id_concierto">
                
                <div style="height:15px;"></div>
                
                <div class="concierto-body">
                    <div class="row" style="color:white">
                        <div class="col-lg-2 col-md-12 col-12 order-md-1 order-2">
                            <div class="fecha_lg">
                                <p class="text-center f_dia">
                                    <span class="texto-normal-bold s16 upper let_sp">{{getDiaNombre(f.fecha_concierto)}}</span>
                                </p>
                                <h5 class="text-center">
                                    <span class="texto-butler s100"> {{getDia(f.fecha_concierto)}} </span> <br>
                                </h5>
                                <p class="text-center f_mes">
                                    <span class="texto-normal-bold s16 upper let_sp"> {{getMes(f.fecha_concierto)}} <br> {{getAno(f.fecha_concierto)}} </span>
                                </p>
                            </div>
                            <div class="fecha_sm">
                            <p class="texto-normal-bold upper let_sp">
                                {{getDiaNombre(f.fecha_concierto)}},
                                {{getDia(f.fecha_concierto)}}
                                {{getMes(f.fecha_concierto)}} {{getAno(f.fecha_concierto)}}
                            </p>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-12 order-md-2 order-3">
                            
                            <router-link class="white link" :to="{name:'ConciertoDetalle',params:{id:f.id_concierto}}">
                                <h5 class="texto-times-bold s38">
                                    <span v-if="idioma == 'ES'"> {{f.nombre}} </span>
                                    <span v-else> {{f.nombre_eng}} </span>
                                </h5>
                            </router-link>
                            
                            <span class="texto-normal-bold s16 let_sp"> <i class="fas fa-clock amarillo"></i> {{f.hora}}</span> &nbsp;&nbsp;&nbsp;
                            <span class="texto-normal-bold s16 upper let_sp"> <i class="fas fa-map-marker-alt amarillo"></i> {{f.lugar}}</span>
                            <p class="pt-4 texto-normal s16">
                                <span v-if="idioma == 'ES'"> {{f.descr}} </span>
                                <span v-else> {{f.descr_eng}} </span>
                            </p>
                            <div class="row">
                                <div class="col-md-6 col-6">
                                    <p class="texto-normal-bold s14">
                                        <span class="let_sp">{{txt_director}}</span> <br>
                                        <span class="texto-normal">{{f.artista1}}</span>
                                    </p>
                                </div>

                                <div class="col-md-6 col-6">
                                    <p class="texto-normal-bold s14"> 
                                        <span class="let_sp">{{txt_solista}}</span><br>
                                        <span class="texto-normal"> {{f.artista2}} </span>
                                    </p>
                                </div>
                            </div>

                            <p class="texto-normal-bold s14 let_sp">{{entrada_general}} $ {{f.precio}} </p>

                            <div class="pt-4">
                                <router-link class="href-chica s18 link" :to="{name:'ConciertoDetalle',params:{id:f.id_concierto}}">{{ver_evento}}</router-link> &nbsp;&nbsp;&nbsp;
                                <a :href="f.url_ticket" class="href-chica s18 link">{{comprar_boletos}}</a>
                            </div>
                        </div>
                        
                        <div class="col-lg-5 col-md-6 col-12 order-md-3 order-1">
                             <router-link class="white link" :to="{name:'ConciertoDetalle',params:{id:f.id_concierto}}">
                               <img :src="url + 'uploads/' + f.url_img" class="img-concierto">
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!--Galeria y Noticias -->
    <div class="section-black pt-7">
        <div class="container-fluid">
            <div class="row padding-all pb-7">
                <div class="col-lg-6 col-md-6">
                    <h2 class="letra3" v-if="idioma == 'ES'">GALERIA <span class="texto-times-italic s38"> y </span> <br> NOTICIAS</h2>
                    <h2 class="letra3" v-else >GALLERY <span class="texto-times-italic s38"> and </span> <br> NEWS</h2>
                </div>
                <div class="col-lg-6 col-md-6">
                     <p class="text-center pt-5  link_001 float-end">
                        <router-link to='/Galeria' class="href-chica s26 link">{{ver_post}}</router-link>
                        &nbsp;&nbsp;&nbsp;
                        <i class="fas fa-arrow-right arrow"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!--Galeria y Noticias Cuerpo-->
    <div class="section-black">
        <div class="container-fluid white">
            <div class="row padding-all pb-7">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 pt-lg-0 pt-md-5 pt-sm-5 pt-5" v-for="f in audiciones" :key="f.id_convocatoria">
                    
                    <router-link class="link" style="color:#ebebeb" :to="{name:'GaleriaDetalle',params:{id:f.id_galeria}}">
                        <img :src="url + 'uploads/' + f.url_img" class="img-concierto_001">
                    </router-link>
                    
                    <p class="pt-4 texto-normal-bold fw800 txt_fecha_concierto">
                        <span style="text-transform: capitalize;"> {{getDiaNombre(f.fecha_galeria)}} </span> 
                        {{getDia(f.fecha_galeria)}} 
                        {{getMes(f.fecha_galeria)}}, 
                        {{getAno(f.fecha_galeria)}}
                    </p>

                    <router-link class="link href-chica" :to="{name:'GaleriaDetalle',params:{id:f.id_galeria}}">
                        <p class="texto-times-bold s30" style="line-height:110%">
                            <span v-if="idioma == 'ES'">{{f.titulo}}</span>
                            <span v-else>{{f.titulo_eng}}</span>
                        </p>
                    </router-link>
                    
                    <p class="pt-2 texto-normal s18">
                        <span v-if="idioma == 'ES'"> {{f.resumen}} </span>
                        <span v-else> {{f.resumen_eng}} </span>
                    </p>
                    
                    <p class="pt-3">
                        <router-link class="href-chica s18 link" :to="{name:'GaleriaDetalle',params:{id:f.id_galeria}}">
                            <span v-if="idioma == 'ES'">Leer Más</span>
                            <span v-else>See More</span>
                        </router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
    
    
    <!--Nuestras Sedes -->
    <div class="pt-7">
        <div class="container">
            <div class="row paddingizq white">
                <div class="col-lg-12">
                    <p class="texto-times-bold-italic s60 text-center">
                        <span v-if="idioma == 'ES'"> Nuestras Sedes </span>
                        <span v-else> Our locations </span>
                    </p>
                </div>
            </div>
        </div>
    </div>


    <div class="pt-7">
        <div class="container-fluid white">
            <div class="row">
                <div class="col-lg-6 sede1">
                    <div class="cuerpo-sede">
                        <p class="texto-times-bold text-center s38">Teatro Aguascalientes</p>
                        <p class="text-center texto-normal s16">
                            <span>Av. José María Chávez S/N,</span> <br>
                            <span>Colonia Jardines de la Asuncion </span> <br>
                            <span>20270 Aguascalientes, Ags. México.</span>
                        </p>

                        <p class="text-center">
                            <a class="link href-chica s24" target="_blank" href="https://www.google.com/maps/place/Teatro+Aguascalientes/@21.857127,-102.291545,15z/data=!4m5!3m4!1s0x0:0x46f61ee1263ec663!8m2!3d21.857127!4d-102.291545">{{ver_mapa}}</a>
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 sede1">
                    <div class="cuerpo-sede">
                        <p class="texto-times-bold text-center s38">Sala de Conciertos Ficotrece</p>
                        <p class="text-center texto-normal s16">
                            <span>Av. José María Chávez S/N,</span> <br>
                            <span>Colonia Jardines de la Asuncion </span> <br>
                            <span>20270 Aguascalientes, Ags. México.</span>
                        </p>

                        <p class="text-center">
                            <a target="_blank" class="link href-chica s24" href="https://www.google.com/maps/place/Sala+de+Conciertos/data=!4m7!3m6!1s0x8429ef2d005533fd:0xc47ffe67933b2c35!8m2!3d21.888631!4d-102.2813715!16s%2Fg%2F11ffw5w1s7!19sChIJ_TNVAC3vKYQRNSw7k2f-f8Q?authuser=0&hl=en&rclk=1">{{ver_mapa}}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

  </div>
</template>


<script>
import Menu from '@/components/Menu'
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'

export default {
name: 'Home',
mixins: [ globalFunctions ],
components:{ Menu },
data () {
  return {
    idioma: localStorage.idioma,
    showAdmin: '',
    conciertos: [],
    prox_conciertos: [],
    audiciones: [],
    txt_director: 'DIRECTOR ARTISTICO',
    entrada_general: 'PRECIO DESDE',
    ver_evento: 'Ver Evento',
    comprar_boletos: 'Comprar Boletos',
    txt_solista: 'CON',
    txt_nohay: 'No se encontraron conciertos para esta fecha',
    /*TRADUCCIONES*/
    somoslaosa: 'Somos La OSA',
    descubre_osa: 'Descubre La OSA',
    descubre_osa2: 'Conoce Más de la OSA',
    con_dif_sedes: 'Con sede en el Teatro Aguascalientes, una de las orquestas más importantes del país te espera con las puertas abiertas.',
    ver_conciertos: 'Ver Todos Los Conciertos',
    ver_post: 'Ver Todos los Posts',
    ver_mapa:'Ver En Mapa',
    laosa_title: 'La OSA'
  }
},
computed:{
  ...mapState(['url'])
},
methods: {
  getData(){
    let data = {
      id: 1,
      flag: 0
    }

    this.axios.post(this.url + "getConcierto.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data != null){
          this.conciertos = responce.data;
        } else {
          this.conciertos = [];
        }
    });

    let data2 = {
      id: 1,
      flag: 5
    }

    this.axios.post(this.url + "getConcierto.php", data2).then((responce) => {
        //console.log(responce.data);
        if(responce.data != null){
          this.prox_conciertos = responce.data;
        } else {
          this.prox_conciertos = [];
        }
    });

    let data3 = {
      flag: 4
    }

    this.axios.post(this.url + "getConvocatoria.php", data3).then((responce) => {
        //console.log(responce.data);
        this.audiciones = responce.data;
    });

  },
  getEnglish(){
        this.txt_director = 'ART DIRECTOR';
        this.txt_solista = 'FEATURING';
        this.entrada_general = 'PRICE STARTS FROM';
        this.ver_evento = 'See Event';
        this.comprar_boletos = 'Buy Tickets';
        this.txt_nohay = 'No concerts found for this date';
        this.somoslaosa = 'We are the OSA';
        this.descubre_osa = 'About the OSA';
        this.con_dif_sedes = 'Based on the Aguascalientes Theater, the Aguascalientes Symphony Orchestra is one of the most important orchestras in Mexico and is waiting for you with its open doors.';
        this.ver_conciertos = 'See all the Concerts';
        this.ver_mapa = 'See map';
        this.descubre_osa2 = 'Learn more about The OSA';
        this.laosa_title = 'The OSA';
        this.ver_post= 'See All Posts';

  },
  terminar(){
    this.showAdmin = false;
    this.setInit();
  },
  checkToken(){
      const token = localStorage.token;
  
      let data = {
        token: token,
      }

      this.axios.post(this.url + "checkToken.php", data).then((responce) => {
          if(responce.data == 200){
              this.showAdmin = true;
          }else{
              this.showAdmin = false;
          }
      });
            
  }
},
mounted(){
  this.checkToken();

  window.scrollTo(0, 0);
  this.getData();
  
  if(this.idioma == 'EN'){
    this.getEnglish();
  }
}

}
</script>


<style scoped>
.cuerpo{
  background:/* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.7), 
          rgba(0, 0, 0, 0.7)
        ),
        url('../assets/osa_hero.jpg');
    background-size: cover;
    position: relative;
    min-height: 90vh;
}

.txt_002{
    font-size: 20px;
}

.cuerpo_evento{
    border: 3px solid #0C0D0E;
    height: 384px;
    width: 100%;
    /*background: linear-gradient(185deg,rgba(17, 19, 20, 0.22), 40%, #111214 95.83%), url('../assets/osa_sede_teatro_ags.jpg');*/
    background-size: cover;
    object-fit: cover;
}

.txt_fecha_concierto{
    font-size: 18px;
}

.txt_001{
    position: relative;
    top: -15px;
    font-size: 40px;
}

.cuerpo_evento:hover{
    border: 3px solid #daa23f;
    transition: 1s;
}

.cuerpo_evento:hover h5{
    color: #daa23f;
    transition: 1s;
}

.section-padding{
    padding-left:8%;
    padding-right:8%;
}

.section-black{
    background-color: #0C0D0E;
}

.cuerpo-sede{
    position: relative;
    top: 220px;
}

.sede1{
    background:/* top, transparent black, faked with gradient */ 
        linear-gradient(180deg,
          rgba(17, 18, 20, 0.35), 
          rgba(17, 18, 20, 0.95)
        ),
        url('../assets/osa_sede_teatro_ags.jpg');
    background-size: cover;
    position: relative;
    height: 500px;
}

.laosa-cuerpo-texto{
    padding-top: 95px;
    padding-left: 100px;
    padding-right: 100px;
}

.laosa{
    background-color: white;
    height: 720px;
}

.text_evento{
    padding-top: 140px;
}

.texto_evento_inner{
    height: 80px;
}

.laosa-img{
    height: 720px;
    background-image: url('../assets/osa_img_2.jpg');
    background-size: cover; /* Resize the background image to cover the entire container */
    position: relative;
}

.laosa-letra{
    font-family: 'HKGrotesk-regular', sans-serif;
    font-size: 20px;
    line-height: 160%;
    font-weight: normal;
}

.img-gobierno{
    position: relative;
    top: 50px;
}

.section-laosa2{
    color: black;
}

.letra1{
    font-family: 'Playfair-italic', sans-serif;
    font-size: 40px;
    color: white;
    font-weight: 400;
}

.letra2{
    font-family: 'HKGrotesk', sans-serif;
    font-size: 40px;
    color: white;
    line-height: 1.25;
    /*font-weight: 900;*/
}

.letra3{
    font-family: 'HKGrotesk', sans-serif;
    font-size: 56px;
    color: white;
    line-height: 95%;
}


/* Medium-small devices (phones, 900px and down) */
@media only screen and (max-width: 980px) {

    .section-padding{
        padding-left: 3%;
        padding-right: 3%;
    }

    .letra3{
        font-size: 48px;
    }

    .letra1{
        font-size: 31px;
    }

    .letra2{
        font-size: 31px;
    }

    .cuerpo_evento{
        height: 280px;
        width: 100%;
    }

    .text_evento{
        padding-top: 70px;
    }

    .txt_001{
        top: 0px;
        font-size: 25px;
    }

    .texto_evento_inner{
        height: 100px;
    }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 700px) {
    
    .laosa-cuerpo-texto{
        padding-left: 15px;
        padding-right: 15px;
    }

    .cuerpo_evento{
        height: 200px;
    }

    .text_evento{
        padding-top: 60px;
    }

    .txt_fecha_concierto{
        font-size: 10px;
    }

    .txt_001{
        font-size: 22px;
    }

    .txt_002{
        font-size: 13px;
    }

    .texto_evento_inner{
        height: 60px;
    }

    .cuerpo{
        background-position: center;
    }

    .letra1{
        font-size: 25px;
    }

    .letra2{
        font-size: 25px;
        line-height: 1.15;
    }
}
</style>