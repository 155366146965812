import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Orquesta from '../views/Orquesta.vue'
import Galeria from '../views/Galeria.vue'
import Conciertos from '../views/Conciertos.vue'
import Integrantes from '../views/Integrantes.vue'
import Convocatorias from '../views/Convocatorias.vue'
import Contacto from '../views/Contacto.vue'
import DetalleIntegrantes from '../views/DetalleIntegrantes.vue'
import GaleriaDetalle from '../views/GaleriaDetalle.vue'
import ConciertoDetalle from '../views/ConciertoDetalle.vue'
import ConvocatoriasDetalle from '../views/ConvocatoriasDetalle.vue'
import Login from '../views/Login.vue'
import NotFound from '../views/NotFound.vue'
import Prueba from '../views/Prueba.vue'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Prueba',
    name: 'Prueba',
    component: Prueba
  },
  {
    path: '/Login_osa',
    name: 'Login',
    component: Login
  },
  {
    path: '/ConvocatoriasDetalle/:id',
    name: 'ConvocatoriasDetalle',
    component: ConvocatoriasDetalle
  },
  {
    path: '/ConciertoDetalle/:id',
    name: 'ConciertoDetalle',
    component: ConciertoDetalle
  },
  {
    path: '/Orquesta',
    name: 'Orquesta',
    component: Orquesta
  },
  {
    path: '/GaleriaDetalle/:id',
    name: 'GaleriaDetalle',
    component: GaleriaDetalle
  },
  {
    path: '/DetalleIntegrantes/:id',
    name: 'DetalleIntegrantes',
    component: DetalleIntegrantes
  },
  {
    path: '/Galeria',
    name: 'Galeria',
    component: Galeria
  },
  {
    path: '/Conciertos',
    name: 'Conciertos',
    component: Conciertos
  },
  {
    path: '/Integrantes',
    name: 'Integrantes',
    component: Integrantes
  },
  {
    path: '/Convocatorias',
    name: 'Convocatorias',
    component: Convocatorias
  },
  {
    path: '/Contacto',
    name: 'Contacto',
    component: Contacto
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
