<template>
  <div>
    
    <div class="cuerpo">
        <Menu :type='2'/>
        <div class="texto-hero container-fluid pt-4 animate__animated animate__fadeIn">
            <h3 class="pt-5 titulo1 s23 fw400 text-center">
              <span v-if="idioma == 'ES'">Orquesta Sinfónica de Aguascalientes</span>
              <span v-else>Aguascalientes Symphony Orchestra</span>
            </h3>
            <h2 class="titulo2 s80 text-center">
              <span v-if="idioma == 'ES'">LA ORQUESTA</span>
              <span v-else>THE ORCHESTRA</span>
            </h2>

            <p class="text-center pt-4" style="color:white">
              <router-link to="/Integrantes" class="botonMas link" style="line-height: 17.8px !important;">
                  <span class="textMas" v-if="idioma == 'ES'">Conoce a los Miembros</span>
                  <span class="textMas" v-else>Meet the members</span>
              </router-link>
            </p>
        </div>
    </div>

    <div class="separador"></div>

    <!--PASION POR LA MUSICA -->
    <div class="container-fluid"> 
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <img src="../assets/osa_convocatorias_1.png" class="img-concierto-main" alt="">
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="laosa_1" >
            <p class="upper texto-normal-bold fw900 ls16 s16 texto-yellow">
              <span v-if="idioma == 'ES'">pasión por la música</span>
              <span v-else>passion for music</span>
            </p>
            
            <p class="texto-times-bold-italic pt-3 white s60" style="line-height:95.5%">
              <span v-if="idioma == 'ES'">La OSA, una casa de puertas abiertas</span>
              <span v-else>The OSA, a house with open doors</span>
            </p>
            
            <p class="pt-5 white texto-normal s20" style="line-height: 160%" v-if="idioma == 'ES'">
             La Orquesta Sinfónica de Aguascalientes es uno de las agrupaciones más importantes de todo el país. Su historia comienza hacia 1908, pero en 1992 
             experimenta cambios significativos en su estructura. <br> <br> A lo largo de esos años, no ha perdido la vocación que la caracteriza: ser una 
             casa abierta en donde juntos celebramos la música. Con sede en el <span class="texto-normal-bold"> Teatro Aguascalientes </span> y actualmente bajo 
             la dirección de <span class="texto-normal-bold"> Emmanuel Siffert</span>, 
             la OSA te espera a ti y a tu familia.
            </p>

            <p class="pt-5 white texto-normal s20" style="line-height: 160%" v-else>
              <span class="texto-normal-bold"> Aguascalientes Symphony Orchestra </span> is one of the most important music ensembles in Mexico. 
              Its history started in 1908, but in 1992 the OSA experienced 
              major changes in its structure. <br> <br> Throughout those years, it has not lost its vocation that characterizes her:
              to be an <span class="texto-normal-bold"> open-door house </span> where we celebrate music together. Based on the Aguascalientes Theater, 
              and currently directed by <span class="texto-normal-bold"> Emmanuel Siffert</span>, the OSA waits for you and your family.
            </p>
            
            <div class="pt-5">
                <div class="link_osa link_001">
                  <router-link to='/Integrantes' class="href-chica s26 link">
                    <span v-if="idioma == 'ES'">Conoce A Los Miembros De La OSA</span>
                    <span v-else>Meet the members</span>
                  </router-link>
                  <i class="fas fa-arrow-right arrow" style="margin-left: 15px"></i>
                </div>
                
            </div>
          </div>
          
        </div>
      </div>
    </div>

    <div class="separador"></div>

    <!-- Historia -->
    <div class="container-fluid laosahistoria">
      <div class="row">
        <div class="col-lg-10">
          
          <div class="white padding-left">
            <p class="upper texto-normal-bold fw900 ls16 s16 texto-yellow">
              <span v-if="idioma == 'ES'">historia</span>
              <span v-else>history</span>  
            </p>
            <p class="texto-times-bold-italic s60" style="line-height:95.5%">
              <span v-if="idioma == 'ES'">Los cimientos de la orquesta</span>
              <span v-else>The foundations of the orchestra</span>
            </p>
            <p class="texto-normal pt-5 s20" style="line-height: 160%" v-if="idioma == 'ES'">
             La historia de la Orquesta Sinfónica de Aguascalientes se remonta a inicios del siglo XX. En 1908, se crea la primera Orquesta del Teatro Salón 
             “Vista Alegre”, que seis años más tarde pasa a ser la Orquesta del Teatro Morelos. <br><br>
              En 1922 surge la Orquesta de Aguascalientes, por iniciativa del secretario de Educación Pública José Vasconcelos y el apoyo del maestro 
              <span class="texto-normal-bold"> Manuel M. Ponce; </span> fue dirigida por el maestro <span class="texto-normal-bold">Miguel Macías Femat </span> 
              y más tarde por el maestro Apolonio Arias. <br><br>
              En 1927, el historiador Alejandro Topete del Valle lanza la iniciativa de reorganizar la Orquesta de Aguascalientes. Se une el profesor 
              <span class="texto-normal-bold"> Arnulfo Miramontes</span>, quien luego sería su director. Un año más tarde, el 3 de marzo, la orquesta inicia labores, 
              brindando un concierto cada mes en el Teatro Morelos. <br><br>
              Hacia 1930, desaparece esta orquesta, pero ese mismo año vuelve a organizarse bajo la coordinación del maestro Miguel Macías Femat, quien la dirige 
              hasta 1945. Posterior a esto, y hasta 1951, el maestro <span class="texto-normal-bold">Ricardo García </span> funge como director. <br><br>
              
              Entre 1951 y 1982, esta agrupación es dirigida por el maestro <span class="texto-normal-bold">José Ruiz Esparza Vega </span>. 
              Disminuye con el paso del tiempo su presencia y actuaciones; hacia 1967 queda aún reconocida, pero sin presencia permanente. <br><br>
              Luego, ya como parte del Instituto Cultural de Aguascalientes, se crea la Orquesta de Cámara de Aguascalientes, incorporando a algunos 
              músicos de la casi extinta Orquesta Sinfónica, así como a nuevos músicos locales. La dirige el maestro 
              <span class="texto-normal-bold">Filiberto Ramos González </span>, quien es reconocido como director artístico de la Orquesta de 
              Cámara de Aguascalientes entre 1988 a 1991.
            </p>

            <p class="texto-normal pt-5 s20" style="line-height: 160%" v-else>
              Aguascalientes Symphony Orchestra’s history can be traced to the early XX century. In 1908, the first orquesta of theater salon “Vista Alegre” 
              was created and six years later it became the Orchestra of The Morelos Theater. <br><br>
              In 1922, with the lead of the Public Education Secretary "José Vasconcelos, and with the support of the musician 
              <span class="texto-normal-bold"> Manuel M. Ponce; </span> The Aguascalientes Orchestra was created. During this time it was 
              lead by the music director <span class="texto-normal-bold">Miguel Macías Femat</span>, and later on by Apolonio Arias. <br><br>
              In 1927, historian Alejandro Topete del Valle launched the initiative to reorganize the Aguascalientes Orchestra.
              Professor <span class="texto-normal-bold"> Arnulfo Miramontes</span> joined the project, who would later be appointed music director. 
              A year later, on March 3rd, the orchestra started activities, performing a concert each month in the Morelos Theater. <br><br>
              Towards 1930, this orchestra disappeared, in spite of that, that same year it was reorganized under the coordination of Miguel Macías Femat, 
              who was music director until 1945. After this period, and until 1951, <span class="texto-normal-bold">Ricardo García </span> was the music director.<br><br>
              Between 1951 and 1982, the OSA was directed by <span class="texto-normal-bold">José Ruiz Esparza Vega</span>. Over time, the orchestra reduced its concerts; around 1967 
              it was still renowned, however it did not had a permanent presence. <br> <br>
              After becoming part of the Aguascalientes Cultural Institute, Aguascalientes Chamber Orchestra was created, adding some of the former musicians members
              of the almost extinct Symphony Orchestra, as well as several new local musicians. The orquesta was directed by 
              <span class="texto-normal-bold">Filiberto Ramos González </span> who was recognized as aguascalientes chamber Orchestra's music director 
              between 1988 and 1991.
            </p>

          </div>
          <div class="div-concierto">
            <img src="../assets/osa_grupal_1998.jpg" class="img-orquesta pt-5"><br><br>
            <small class="white texto-times-italic s18">
              <span v-if="idioma == 'ES'">
                Foto grupal de la OSA en 1998 aproximadamente, con el director Enrique Barrios
              </span>
              <span v-else>
                Group photo of the OSA approximately in 2001, with the director Enrique Barrios
              </span>
            </small>
          </div>
        </div>
      </div>
    </div>

    <!-- Historia seccion 2-->
    <div class="container-fluid laosahistoria">
      <div class="row">
        <div class="col-lg-10">
          
          <div class="white padding-left">
            
            <p  v-if="idioma == 'ES'" class="texto-normal pt-5 s20" style="line-height: 160%">
              A principios de 1992, se toman medidas significativas, como la transformación de la Orquesta de Cámara en Orquesta Sinfónica, con el nombre de Orquesta 
              del Teatro de Aguascalientes, así como la contratación del maestro <span class="texto-normal-bold">Alfredo Ibarra</span> como director. 
              Gracias a su entusiasmo y a sus estudios de dirección en el extranjero, el conjunto recibe el último impulso para culminar esta fase 
              importante de profesionalización. <br><br>
              Con una plantilla propia de cuarenta músicos profesionales, bajo la dirección del maestro Ibarra, Iury Loskutov como primer violín y la
              pianista Kasuko Shioda como solista, la Orquesta hace su debut en el Teatro de Aguascalientes el viernes <span class="texto-normal-bold">28 de febrero de 1992</span>. 
              En el programa inaugural destacaron el “Concierto Para Piano y Orquesta Número 2” de Sergei Rachmaninoff y la “Quinta Sinfonía” de Ludwig van 
              Beethoven.<br><br>Desde su punto de partida y hasta noviembre de 1992, periodo del maestro Jesús Gómez Serrano en la dirección del 
              Instituto Cultural de Aguascalientes, 
              la renovada orquesta presenta 40 conciertos, así como la ópera “Carmen”. <br><br>
              Luego de la dirección artística del maestro Alfredo Ibarra, en 1993 llega el maestro <span class="texto-normal-bold">Gordon Campbell</span>. 
              La Orquesta cambia de denominación al nombre con el que hoy se le conoce: <span class="texto-normal-bold">Orquesta Sinfónica de Aguascalientes.</span><br><br>
              A su vez, se funda el <span class="texto-normal-bold">patronato de la OSA</span>, el Ing. Carlos González de Cosío fue el primer presidente. Entre los miembros de la mesa directiva se 
              cuentan el Dr. Alfonso Pérez Romo, el C.P. Humberto Martínez de León, el Sr. Humberto López Aparicio, el Ing. Felipe de Jesús Polina, el L.A.E. 
              Alejandro Lozano Moreno, el C.P. Carlos Masa Arévalo, el L.A.E. Antonio Garza Guerra, entre otros. Fungió como gerente el arquitecto Alberto Serna D
              ávila, con el acompañamiento y apoyo del entonces director del Instituto Cultural de Aguascalientes, el maestro Enrique Rodríguez Varela.
            </p>

            <p class="texto-normal pt-5 s20" style="line-height: 160%" v-else>
              In 1992, the OSA experienced significant rearrangements, such as the transformation from "The Chamber Orchestra" to "The Symphony Orchestra" 
              (named The Aguascalientes Theater Orchestra), as well as the incorporation of the music director <span class="texto-normal-bold">Alfredo Ibarra</span>. 
              Thanks to his efforts and his directing studies abroad, the Orchestra received the last impulse needed to conclude this professionalization phase. <br><br>
              With forty professional musicians under the direction of Alfredo Ibarra, Iury Loskutov as first violin and pianist Kasuko Shioda as a 
              soloist, the orchestra debuted at the Aguascalientes Theater on Friday <span class="texto-normal-bold">February 28th 1992</span>. 
              The inaugural program included Sergei Rachmaninoff’s “Concert for Piano and Orchestra Number 2” and Ludwig van Beethoven’s “Fifth Symphony”.
              <br><br>
              Since this starting point and until November 1992, period in which Jesús Gómez Serrano was Aguascalientes Cultural Institute’s director, 
              the renovated orchestra presented 40 concerts, as well as the opera “Carmen”. <br><br>
              After Alfredo Ibarra’s music direction, in 1993, <span class="texto-normal-bold">Gordon Campbell</span> arrived. The orchestra then changes its 
              name by what is is known today: <span class="texto-normal-bold"> Aguascalientes Symphony Orchestra </span>.
              In that period,<span class="texto-normal-bold">the OSA board of trustees</span> was created, being Ing. Carlos González de Cosío its first president. 
              Some of the members of the directive board were Dr. Alfonso Pérez Romo, C.P. Humberto Martínez de León, Mr. Humberto López Aparicio, 
              Ing. Felipe de Jesús Polina,L.A.E. Alejandro Lozano Moreno, C.P. Carlos Masa Arévalo, L.A.E. Antonio Garza Guerra. etcetera. Arq. Alberto Serna Dávila served 
              as manager, accompanied and supported by the Aguascalientes Cultural Institute director of the time, Enrique Rodríguez Varela.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="separador"></div>
    
    <!--HISTORIA RECIENTE-->
    <div class="container-fluid laosahistoria">
      <div class="row">
        <div class="col-lg-10">
          
          <div class="white padding-left">
            <p class="upper texto-normal-bold fw900 ls16 s16 texto-yellow">
              <span v-if="idioma == 'ES'">historia reciente</span>
              <span v-else>Recent History</span>
            </p>
            <p class="texto-times-bold-italic s60" style="line-height:95.5%">
              <span v-if="idioma == 'ES'">Una trayectoria de tres décadas</span>
              <span v-else>A three-decade history</span>
            </p>
            <p class="texto-normal pt-5 s20" style="line-height: 160%" v-if="idioma == 'ES'">
              Desde su reestructuración, la Orquesta Sinfónica de Aguascalientes ha alcanzado un desarrollo dinámico para acercar nuevos públicos, contar con destacados 
              directores de orquesta invitados, así como seguir erigiéndose como una de las <span class="texto-normal-bold">orquestas más sólidas del país</span>. <br><br>
              Entre 1992 y 2000, durante la dirección artística del maestro <span class="texto-normal-bold">Gordon Campbell</span>, se promueve la asistencia de niños y 
              niñas a los ensayos de los conciertos; 
              a su vez, se realizan actividades de difusión para aumentar la cantidad de público, como conciertos didácticos, giras a los municipios y conciertos navideños, 
              en iglesias y centros comerciales, 
              galas de ópera con fragmentos de las óperas más célebres. También se presentó el Oratorio de “La Creación” de Joseph Haydn, con un gran elenco operístico y 
              proyección de imágenes de acuerdo a los recitativos, arias y coros de la magna obra. <br><br>
              Entre 2001 y 2004, durante la dirección del Instituto Cultural de Aguascalientes del L.A.E. Alejandro Lozano, el maestro 
              <span class="texto-normal-bold">Enrique Barrios</span> funge como director 
              artístico. Se impulsan aún más los logros de la orquesta en todos sus ámbitos: conciertos didácticos, giras y óperas, entre las que se presentaron fueron 
              “La Traviata”, “La Bohème”, “Madame Butterfly”, “Lucia de Lammermoor”, “Rigoletto”, “Fidelio”, con elencos de cantantes de primer nivel nacional y algunos 
              internacionales. 
            </p>

            <p class="texto-normal pt-5 s20" style="line-height: 160%" v-else>
              Since its restructuring, the Aguascalientes Symphony Orchestra has reached a dynamic development to bring new audiences closer, have outstanding guest 
              conductors, as well as continue to establish itself as one of the <span class="texto-normal-bold">most solid orchestras in the country.</span> <br><br>
              Between 1992 and 2000, during the artistic direction of maestro <span class="texto-normal-bold">Gordon Campbell</span>, the attendance of boys and 
              girls at concert rehearsals 
              was promoted; At the same time, diffusion activities are carried out to increase the number of the public assistance, such as educational concerts, tours to 
              municipalities, Christmas concerts, churches and shopping centers and opera galas with fragments of the most famous operas. Joseph Haydn's “The Creation” 
              Oratorio was also presented, with a great operatic cast and projection of images according to the recitatives, arias and choirs of that great work. <br><br>
              Between 2001 and 2004, during the direction of the Cultural Institute of Aguascalientes of the L.A.E. Alejandro Lozano, <span class="texto-normal-bold">Enrique Barrios</span> 
              acts as artistic director. 
              The achievements of the orchestra are further promoted in all its fields: educational concerts, tours and operas, among which "La Traviata", "La Bohème"
              "Madame Butterfly", "Lucia de Lammermoor", "Rigoletto ”, “Fidelio” were presented,
              with a top-level national singers and some international.
            </p>
          </div>
          
          <div class="div-concierto">
            <img src="../assets/osa_programas_archivo.png" class="img-orquesta2 pt-5"><br><br>
          </div>

        </div>
      </div>
    </div>

    <!--Historia Reciente 2 -->
    <div class="container-fluid laosahistoria">
      <div class="row">
        <div class="col-lg-10">
          
          <div class="white padding-left">
            
            <p class="texto-normal pt-5 s20" style="line-height: 160%" v-if="idioma == 'ES'">
              También se realizaron diversas puestas de ballet con compañías de reconocimiento internacional como el “Ballet de Montecarlo”, temporadas temáticas, 
              como la de conciertos para violín con violines Stradivarius, una temporada Beethoven que abarcó sus nueve sinfonías, los “Cinco conciertos para piano”, 
              el “Concierto para violín”, el  “Triple concierto para violín, violoncello y piano”, la “Fantasía Coral”, “Oberturas Fidelio”, “Egmont”, “Leonora 1, 2 y 3”, 
              “La Consagración de la Casa”, entre otros. <br><br>
              En el periodo de 2005 a 2016, en la dirección artística del maestro <span class="texto-normal-bold">Román Revueltas</span>, se extiende la actividad musical más allá de sus tres temporadas
              regulares por año; de igual forma, se impulsa la grabación de diversos discos y las presentaciones en festivales de talla internacional. <br><br>
              Se producen las óperas “El Barbero de Sevilla”, “La Flauta Mágica” y “Carmen”. Esta última se presenta en la Plaza de Toros San Marcos y contó con la
              faena de un toro con el matador Fabián Barba Rivas, entre el segundo y tercer acto de la ópera. Este largo periodo de la dirección del maestro Revueltas 
              se desarrolló bajo las direcciones del Instituto Cultural de Aguascalientes del Dr. Víctor González Esparza, el Arq. Martín Andrade y la maestra Dulce 
              María Rivas Godoy. <br><br>
              Durante la dirección de la arquitecta Claudia Santa Ana del Instituto Cultural de Aguascalientes, la OSA cuenta con las direcciones artísticas del maestro 
              <span class="texto-normal-bold">José Areáns</span> (2017-2020) y el maestro <span class="texto-normal-bold">Lanfranco Marcelletti</span> (2020-2021). En la administración del director general 
              Carlos Reyes Sahagún se nombra como director
              interino al maestro <span class="texto-normal-bold">Jesús García Santín</span> y, a partir de febrero de 2022, al maestro <span class="texto-normal-bold">Emmanuel Siffert</span>. <br><br>
              Todos y cada uno ellos han continuado impulsando a la OSA hacia nuevos públicos, enfrentado retos como la pandemia por COVID-19, así como prolongado 
              el legado de una orquesta que celebra sus <span class="texto-normal-bold">primeras tres décadas</span>.
            </p>

            <p class="texto-normal pt-5 s20" style="line-height: 160%" v-else> 
              There were also various ballet performances with companies internationally recognized such as the “Ballet de Montecarlo”, thematic seasons, 
              such as violin concerts with Stradivarius violins, a Beethoven season that included his nine symphonies, the “Five piano concert”, the “Violin Concert”, 
              the “Triple Concert for violin, cello and piano”, the “Choral Fantasy”, “Fidelio Overtures”, “Egmont”, “Leonora 1, 2 and 3”, 
              “The Consecration of the House”, among others. <br><br>
              In the period from 2005 to 2016, under the artistic direction of maestro <span class="texto-normal-bold">Román Revueltas</span>, 
              the musical activity extends beyond its three regular seasons per year; In the same way, the recording of various albums and 
              presentations at international festivals are promoted. <br><br>
              The operas "The Barber of Seville", "The Magic Flute" and "Carmen" are also produced during this time. This last one is presented in the Plaza de Toros 
              San Marcos and included a bull fight festival with the bull fighter Fabián Barba Rivas, between the second and third acts of the opera. 
              This long period under the direction of the teacher Revueltas was developed following the direction of "The Cultural Institute of Aguascalientes" under
              Dr. Víctor González Esparza, the Architect Martín Andrade and the teacher Dulce María Rivas Godoy. <br><br>
              
              During the direction of the architect Claudia Santa Ana of the Cultural Institute of Aguascalientes, the OSA has the artistic directions of 
              <span class="texto-normal-bold">José Areáns</span>  (2017-2020) and <span class="texto-normal-bold">Lanfranco Marcelletti</span>(2020-2021). In the administration of the general director Carlos Reyes Sahagún, the teacher 
              <span class="texto-normal-bold">Jesús García Santín</span> is appointed as interim director and, as of February 2022, the teacher 
              <span class="texto-normal-bold">Emmanuel Siffert</span> was appointed. <br><br>
              Each and every one of them has continued to promote the OSA towards new audiences, facing challenges such as the COVID-19 pandemic, as well as 
              extending the legacy of an orchestra that celebrates <span class="texto-normal-bold">its first three decades</span>.
            </p>
          </div>

           <div class="div-concierto">
            <img src="../assets/osa_grupal_1998.jpg" class="img-orquesta pt-5"><br><br>
          </div>

        </div>
      </div>
    </div>

    <div class="separador"></div>

    <!--TRAYECTORIA Y ACTIVIDADES -->
    <div class="container-fluid laosahistoria">
      <div class="row">
        <div class="col-lg-10">
          
          <div class="white padding-left">
            <p class="upper texto-normal-bold fw900 ls16 s16 texto-yellow">
              <span v-if="idioma == 'ES'">trayectoria y actividades</span>
              <span v-else> history and activities </span>
            </p>
            <p class="texto-times-bold-italic s60" style="line-height:95.5%">
              <span v-if="idioma == 'ES'">La OSA en la Actualidad</span>
              <span v-else>The OSA Today</span>
            </p>
            <p v-if="idioma == 'ES'" class="texto-normal pt-5 s20" style="line-height: 160%">
             La Orquesta Sinfónica de Aguascalientes presenta <span class="texto-normal-bold">cuatro temporadas</span> regulares al año. Reconocida por su 
             calidad interpretativa, tiene su sede en el Teatro Aguascalientes y cuenta también con una Sala de Conciertos en el Macro Espacio para la 
             Cultura y las Artes. <br><br>
             Con cuatro grabaciones discográficas, entre las que se cuentan una dedicada a la obra de Silvestre Revueltas, la OSA, a lo largo de su 
             historia, ha buscado siempre <span class="texto-normal-bold">nuevos escenarios</span>: montajes de óperas, conciertos especiales, conciertos didácticos, 
             así como presentaciones 
             en espacios alternativos y en festivales de talla mundial como el Festival Cervantino o el Festival de la Ciudad de México. <br><br>
             Si algo la distingue es que es una <span class="texto-normal-bold">gran familia</span>, una en la que siempre caben más miembros. 
             <span class="texto-normal-bold">¡Acompáñanos!</span>
            </p>

            <p class="texto-normal pt-5 s20" style="line-height: 160%" v-else>
              Each year, the Aguascalientes Symphony Orchestra presents <span class="texto-normal-bold">four regular music seasons</span>.Renowned for its 
              performance quality, 
              the OSA has its home at the Aguascalientes Theater, and also owns a Concert Hall at the Macro Espacio para la Cultura y las Artes, 
              within the Complejo Ferrocarrilero Tres Centurias. <br><br>
              With four CD recordings, among which, one is dedicated to Silvestre Revueltas legacy, the OSA, throughout its history, 
              has always searched for <span class="texto-normal-bold">new scenarios</span>: opera performances, special concerts, didactic concerts, as well as 
              performances in alternative compounds and world class festivals such as the Festival Cervantino or the Mexico City Festival. <br><br>
              Perhaps the main attribute of the OSA is that it is a <span class="texto-normal-bold">huge family</span>, one in which there can 
              be even more members. <span class="texto-normal-bold">Join us!</span>
            </p>
          </div>

        </div>

      </div>
    </div>

    <div class="separador"></div>

    <div class="container-fluid">
      <img src="../assets/osa_temporada_1.jpg" class="img-orquesta"><br><br>
    </div>

  </div>
</template>


<script>
import Menu from '@/components/Menu'
import { Modal } from 'bootstrap'
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'

export default {
name: 'Integrantes',
mixins: [ globalFunctions ],
components:{ Menu },
data () {
  return {
    idioma: localStorage.idioma,
    modal: null,
    modal2: null,
    showAdmin: '',
  }
},
computed:{
  ...mapState(['url'])
},
methods: {
  getData(){

  },
  getEnglish(){

  },
  terminar(){
    this.showAdmin = false;
    this.setInit();
  },
  checkToken(){
      const token = localStorage.token;
  
      let data = {
          token: token,
      }

      this.axios.post(this.url + "checkToken.php", data).then((responce) => {
          if(responce.data == 200){
              this.showAdmin = true;
          }else{
              this.showAdmin = false;
          }
      });
            
  }
},
mounted(){
  this.modal = new Modal(this.$refs.exampleModal)
  this.modal2 = new Modal(this.$refs.staticBackdrop)
  
  this.checkToken();

  window.scrollTo(0, 0);
  this.getData();
  
  if(this.idioma == 'EN'){
    this.getEnglish();
  }
}
}
</script>


<style scoped>

.cuerpo{
  background:/* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.7), 
          rgba(0, 0, 0, 0.7)
        ),
        url('../assets/osa_temporada_1.jpg');
  background-size: cover;
  position: relative;
  min-height: 80vh;
}

.div-concierto{
  margin-left: 18%;
}

.img-orquesta{
  /*height: 580px;*/
  width: 100%;
  height: auto;
  object-fit: cover;
}

.img-orquesta2{
  /*height: 580px;*/
  width: 100%;
  height: auto;
  object-fit: cover;
}

.padding-left{
  padding-left: 28%;
  padding-right: 10%;
}

.img-concierto-main{
  width: 90%;
  height: auto;
  object-fit: cover;
}

.img-concierto-main1{
  height: 879px;
  width: 644px;
}

.laosa_1{
    position:relative;
    top:120px;
    padding-left:18px;
    padding-right:105px;
}

/* Medium-small devices (phones, 900px and down) */
@media only screen and (max-width: 980px) {
  
  .img-concierto-main{
    position: relative;
    height: 879px;
    width: 644px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .laosahistoria{
    padding-top: 150px;
  }

  .img-concierto{
    height: 100%;
  }

  .laosa_1{
    padding-left: 18%;
    padding-top: 18px;
  }

  .padding-left{
    padding-left: 18%;
    padding-right:105px;
  }

  .img-concierto-main1{
    height: 100%;
    width: 100%;
  }

  .div-concierto{
    margin-left: 0%;
  }

  .img-orquesta{
    height: 580px;
    width: 100%;
  }

  .img-orquesta2{
    width: 100%;
  }

}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 700px) {

  .img-concierto-main{
    height: 440px;
    width: 100%;
  }

  .laosa_1{
    padding-left: 5px;
    padding-right:0%;
}

  .padding-left{
    padding-left: 1%;
    padding-right: 1%;
  }

  .link_osa{
    float: right;
  }

  .img-orquesta{
    height: 300px;
  }

  .img-orquesta2{
    height: 600px;
  }
}

</style>