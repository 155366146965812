<template>
  <div>
    <!--fixed-top -->
    <nav class="navbar navbar-expand-xl navbar-dark change_color_sm menu" 
         :class="{change_color: scrollPosition > 600, 'fixed-top': scrollPosition > 600, change_color_clicked: overflowCondition == true }">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">
                <img src="../assets/Logo_OSA.png" class="logo"  alt="">
            </a>
            <button class="navbar-toggler" @click="overflowCondition = !overflowCondition" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse menu-body" id="navbarText">
                <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <p class="text-center white">
                            <router-link class="menulink" to="/Conciertos" :class="{notActive: type != 1,linkActive: type == 1}">
                                <span v-if="idioma == 'ES'"> CONCIERTOS </span>
                                <span v-else> CONCERTS </span>
                            </router-link>
                        </p>
                    </li>

                    <li class="nav-item">
                        <p class="text-center white">
                            <router-link class="menulink" to="/Orquesta" :class="{notActive: type != 2,linkActive: type == 2}">
                                <span v-if="idioma == 'ES'"> LA&nbsp;ORQUESTA </span> 
                                <span v-else> THE&nbsp;ORCHESTRA</span>
                            </router-link>
                        </p>
                    </li>

                    <li class="nav-item">
                        <p class="text-center white">
                            <router-link class="menulink" to="/Integrantes" :class="{notActive: type != 3,linkActive: type == 3}">
                                <span v-if="idioma == 'ES'">INTEGRANTES</span>
                                <span v-else>MEMBERS</span> 
                            </router-link>
                        </p>
                    </li>

                    <li class="nav-item">
                        <p class="text-center white">
                            <router-link class="menulink" to="/Galeria" :class="{notActive: type != 4,linkActive: type == 4}">
                                <span v-if="idioma == 'ES'">GALERÍA</span>
                                <span v-else>GALLERY</span>
                            </router-link>
                        </p>
                    </li>

                    <li class="nav-item">
                        <p class="text-center white">
                            <router-link class="menulink" to="/Convocatorias" :class="{notActive: type != 5,linkActive: type == 5}">
                                <span v-if="idioma == 'ES'"> CONVOCATORIAS </span>
                                <span v-else> ANNOUNCEMENTS </span>
                            </router-link>
                        </p>
                    </li>

                    <li class="nav-item">
                        <p class="text-center white">
                            <router-link class="menulink" to="/Contacto" :class="{notActive: type != 6,linkActive: type == 6}">
                                <span v-if="idioma == 'ES'"> CONTACTO </span>
                                <span v-else> CONTACT </span>
                            </router-link>
                        </p>
                    </li>
                    
                </ul>

                <span class="navbar-text">
                    <ul class="nav navbar-nav ms-auto justify-content-end">
                        <li class="nav-item">
                            <p class="text-center white pt-lg-0 pt-md-5">
                                <a class="menuLang" @click="cambiarIdioma('e')" :class="{notActiveIdioma: idioma != 'ES',linkActiveIdioma: idioma == 'ES'}">
                                    <span v-if="idioma == 'ES'">ESP</span>
                                    <span v-else>ESP</span>
                                </a>
                                 <a class="menuLang" @click="cambiarIdioma('i')" :class="{notActiveIdioma: idioma != 'EN',linkActiveIdioma: idioma == 'EN'}">
                                    <span v-if="idioma == 'ES'"> ENG </span>
                                    <span v-else> ENG </span>
                                </a>
                            </p>
                        </li>
                        <li class="nav-item">
                            <p class="text-center white pt-lg-0 pt-md-3 pt-3">
                                <span class="menuLang">
                                    <a href="https://www.instagram.com/orquesta_sinfonica_ags/" target="_blank" class="link">
                                        <i class="redes fab fa-instagram menuRedes"></i>
                                    </a>
                                </span>
                                <span class="menuLang">
                                    <a class="link" href="https://www.facebook.com/OrquestaSinfonicadeAguascalientes" target="_blank">
                                        <i class="redes2 fab fa-facebook-f menuRedes"></i>
                                    </a>
                                </span>
                            </p>
                        </li>
                    </ul>
                </span>

            </div>
        </div>
    </nav>
    
  </div>

</template>

<script>
export default {
props:['type'],
data () {
return {
        msg: '',
        loading: false,
        loadingok: true,
        openClose: [],
        texto: '',
        idioma: '',
        scrollPosition: null,
        overflowCondition: false
    }
},
methods: {
    cambiarIdioma(idioma){
        if (idioma == 'i'){
            localStorage.idioma = 'EN';
            this.idioma = 'EN';
            location.reload();
        }else{
            localStorage.idioma = 'ES';
            this.idioma = 'ES';
            location.reload();
        }
    },
    updateScroll(){
        this.scrollPosition = window.scrollY;
    },
},
mounted(){
    this.idioma = localStorage.idioma;
    
    if (this.idioma == undefined){
        localStorage.idioma = 'ES';
        this.idioma = localStorage.idioma;
    }

    window.addEventListener('scroll', this.updateScroll);
}
}
</script>


<style scoped>

.change_color {
    background-color: #0C0D0E;
}

.menu{
    padding-left:30px;
    padding-right:30px;
}

.redes{
    border: 2px solid #daa23f;;
    border-radius: 15px;
    padding-left: 6px; 
    padding-right: 6px;
    padding-top: 5px; 
    padding-bottom: 5px;
}

.redes2{
    padding-left: 8px; 
    padding-right: 8px;
    padding-top: 5px; 
    padding-bottom: 5px;
    border: 2px solid #daa23f;
    border-radius: 15px;
}

.menulink{
    margin-left: 30px;
    font-size: 14px;
    color: #ebebeb !important;
    font-family: 'HKGrotesk', sans-serif;
}

.menuLang{
    font-size: 15px;
    color: white !important;
    font-family: 'HKGrotesk', sans-serif;
    margin-left: 10px;
    cursor: pointer;
}

.menuRedes:hover{
    color: #daa23f;
}

.logo{
  width: 100px !important;
  height: 100px !important;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}

.linkActive{
    color: black;
    border-bottom: 3px solid #daa23f;
    text-decoration: none;
}

.notActive
{
    color:black;
    text-decoration: none;
}

.notActive:hover{
    color: black;
    text-decoration: none !important;
    border-bottom: 3px solid #daa23f;
}

.linkActiveIdioma{
    color: black;
    border-bottom: 3px solid #daa23f;
    text-decoration: none;
}

.notActiveIdioma
{
    color:black;
    text-decoration: none;
}

.notActiveIdioma:hover{
    color: black;
    text-decoration: none !important;
    border-bottom: 3px solid #daa23f;
}

/* Medium-small devices (phones, 900px and down) */
@media only screen and (max-width: 980px) {
    .nav-item{
        margin: -5px;
    }

    .menulink{
        position: relative;
        font-size: 30px;
    }

    .menuLang{
        font-size: 20px;
        margin-left: 25px;
    }

    .change_color_sm{
        background-color: #0C0D0E;
        margin-left: 0%;
        margin-right: 0%;
    }

    .change_color {
        background-color: #0C0D0E;
        margin-left: 0% !important;
        margin-right: 0% !important;
    }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 700px) {

    .menu{
        padding-left:0px;
        padding-right:0px;
    }

    .change_color_sm{
        background-color: #0C0D0E;
        margin-left: 0% !important;
        margin-right: 0% !important;
    }

    .change_color {
        background-color: #0C0D0E;
        margin-left: 0% !important;
        margin-right: 0% !important;
    }

    .logo{
        width: 60px !important;
        height: 60px !important;
    }

    .navbar-collapse {
        height: 100vh !important;
    }
  
}
</style>