<template>
<div>

  <!--Menu Admin -->
  <div class="menuAdmin container" v-if="showAdmin == true">
    <div class="row p-3">
      <div class="col-lg-6">
        <button type="button" class="btn btn-sm btn-success" @click="modal.show()">
          <i class="fas fa-plus"></i> Integrante
        </button>
      </div>
      <div class="col-lg-6">
        <button class="btn btn-danger float-end btn-sm" @click="terminar()">Terminar</button>
      </div>
    </div>
  </div>

  <!--Menu -->
  <div class="cuerpo">
    <Menu :type='3'/>
    <div class="texto-hero pt-4 animate__animated animate__fadeIn">
        <h3 class="pt-5 titulo1 s23 fw400 text-center"></h3>
        <h2 class="titulo2 s80 text-center txt_001" v-if="idioma == 'ES'">INTEGRANTES <br><span class="texto-times-italic s38 fw400 text_dela">de la</span> ORQUESTA</h2>
        <h2 class="titulo2 s80 text-center txt_001" v-else>MEMBERS <br><span class="texto-times-italic s38 fw400 text_dela">of the</span> ORCHESTRA</h2>
    </div>
  </div>

  <!-- Integrantes -->
  <div class="container-fluid pb-6 white section_int" v-for="f in instrumentos2" :key ="f.instrumentos">
    
    <div class="padding-all">
      <div class="row">
        <div class="col-lg-2 col-6">
          <p class="texto-normal-bold s43" style="line-height: 40px;text-transform: uppercase;">
            <span v-if="idioma == 'ES'"> {{f.nombre_ins}} </span>
            <span v-else> {{f.nombre_ins_eng}} </span>
          </p>
        </div>
      </div>

      <div class="padding_left">
        <div class="row">
          
          <div class="col-lg-3 col-md-4 col-sm-6 col-9 integrante_section" v-for="i in integrantes.filter( o=>o.titulo == f.id_instrumento)" :key="i.id_integrantes">
                
            <p class="text-center" v-if="showAdmin == true">
              <button class="m-1 btn btn-primary btn-sm" @click="showModalEdicion(i)" >
                <i class="fas fa-edit"></i>
              </button>
              <button class="m-1 btn btn-primary btn-sm" @click="showModalEdicionFoto(i)">
                <i class="fas fa-edit"></i> Foto
              </button>
              <button class="m-1 btn btn-danger btn-sm" @click="borrar(i)">
                <i class="fas fa-trash"></i>
              </button>
            </p>

            <div class="pt-5">
              <router-link :to="{name: 'DetalleIntegrantes', params:{id: i.id_integrante}}"> 
                <img :src="url + 'uploads/thumb_' + i.url_img" class="imagen">
              </router-link>
            </div>
            
            <div class="pt-5 footer_int">
              <p class="s22 text-center texto-normal-bold fw900" style="line-height: 100%">
                <router-link class="txt_nombre white" :to="{name: 'DetalleIntegrantes', params:{id: i.id_integrante}}"> {{i.nombre}} {{i.apellido}} </router-link>
              </p>
              
              <p class="text-center s22 texto-times-italic" style="position:relative; line-height: 100%; top: -12px" >
                <span v-if="idioma == 'ES'"> {{f.nombre_ins}} </span>
                <span v-else> {{f.nombre_ins_eng}} </span>
              </p>
            </div>
            
            
              
          </div>
          
        </div>
      </div>
    </div>
    
  </div>

<!-- Modal Alta-->
<div class="modal fade" ref="exampleModal" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Nuevo Integrante</h5>
        <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
      </div>
      <form @submit="alta">
        <div class="modal-body">
          <div class="container-fluid">
            
            <span class="badge bg-secondary">Instrumento/Puesto</span>
            <select class="form-control mt-1" required v-model="titulo">
              <option :value="f.id_instrumento" v-for="f in instrumentos" :key="f.id_instrumento">{{f.nombre_ins}}</option>
            </select>
            
            <div class="row pt-2">
              <div class="col-lg-6">
                <span class="badge bg-secondary">Foto</span>
                <input type="file" class="mt-1 form-control" required @change="addFile"> 
              </div>
            </div>

            <div class="row pt-2">
              <div class="col-lg-6">
                <span class="badge bg-secondary">Nombre</span>
                <input type="text" class="mt-1 form-control" v-model="nombre" placeholder="Nombre" >
              </div>
              <div class="col-lg-6">
                <span class="badge bg-secondary">Apellido</span>
                <input type="text" class="mt-1 form-control" v-model="apellido" placeholder="Apellido" >
              </div>
            </div>

            <div class="row pt-2">
              <div class="col-lg-12">
                <span class="badge bg-secondary">Detalle</span>
                <textarea class="form-control mt-1" v-model="detalle" required rows="4"></textarea> <br>
              </div>
              <div class="col-lg-12">
                <span class="badge bg-secondary">Detalle Inglés</span>
                <textarea class="form-control mt-1" v-model="detalle_eng" required rows="4"></textarea>
              </div>
            </div> 
                      
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal.hide()">Cerrar</button>
          <button type="submit" class="btn btn-success">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal Edicion-->
<div class="modal fade" ref="edicionModal" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar Integrante</h5>
        <button type="button" class="btn-close" @click="modal2.hide()" aria-label="Close"></button>
      </div>
      <form @submit="editar">
        <div class="modal-body">
          <div class="container-fluid">
            
            <span class="badge bg-secondary">Instrumento/Puesto</span>
            <select class="form-control mt-1" required v-model="etitulo">
              <option :value="f.id_instrumento" v-for="f in instrumentos" :key="f.id_instrumento">{{f.nombre_ins}}</option>
            </select>

            <div class="row pt-2">
              <div class="col-lg-6">
                <span class="badge bg-secondary">Nombre</span>
                <input type="text" class="mt-1 form-control" v-model="enombre" placeholder="Nombre" >
              </div>
              <div class="col-lg-6">
                <span class="badge bg-secondary">Apellido</span>
                <input type="text" class="mt-1 form-control" v-model="eapellido" placeholder="Apellido" >
              </div>
            </div>

            <div class="row pt-2">
              <div class="col-lg-12">
                <span class="badge bg-secondary">Detalle</span>
                <textarea class="form-control mt-1" v-model="edetalle" required rows="4"></textarea> <br>
              </div>
              <div class="col-lg-12">
                <span class="badge bg-secondary">Detalle Inglés</span>
                <textarea class="form-control mt-1" v-model="edetalle_eng" required rows="4"></textarea>
              </div>
            </div> 
                      
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal2.hide()">Cerrar</button>
          <button type="submit" class="btn btn-primary">Editar</button>
        </div>
      </form>
    </div>
  </div>
</div>


<!-- Modal Editar Foto-->
<div class="modal fade" ref="modalFoto" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar Foto</h5>
        <button type="button" class="btn-close" @click="modal3.hide()" aria-label="Close"></button>
      </div>
      <form @submit="editar_foto_upload">
        <div class="modal-body">
          <div class="container-fluid">
          
            <div class="row">
              <div class="col-lg-6">
                <span class="badge bg-secondary">Nueva Img</span>
                <input type="file" class="mt-1 form-control" required @change="addFile"> 
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal3.hide()">Cerrar</button>
          <button type="submit" class="btn btn-primary">Editar</button>
        </div>
      </form>
    </div>
  </div>
</div>

</div>
    
</template>

<script>
import Menu from '@/components/Menu'
import { Modal } from 'bootstrap'
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'

export default {
name: 'Integrantes',
components:{ Menu },
mixins: [ globalFunctions ],
computed:{
    ...mapState(['url'])
},
data () {
  return {
    idioma: localStorage.idioma,
    showAdmin: false,
    modal: null,
    modal2: null,
    modal3: null,
    nombre: '',
    apellido: '',
    titulo: '0',
    enombre: '',
    eapellido: '',
    edetalle: '',
    edetalle_eng: '',
    etitulo: '0',
    selectedFile: null,
    fileName : '',
    detalle: '',
    detalle_eng: '',
    integrantes: '',
    instrumentos: '',
    instrumentos2: '',
    id_integrante: '',
    ex_img: ''
  }
},
methods: {
  getData(){
     let data = {
        flag: 0,
      }

      this.axios.post(this.url + "getIntegrantes.php", data).then((responce) => {
          this.integrantes = responce.data;
      });

      let data2 = {
        flag: 2,
      }

      this.axios.post(this.url + "getIntegrantes.php", data2).then((responce) => {
          //console.log(responce.data);
          this.instrumentos2 = responce.data;
      });
  },

  checkToken(){
      const token = localStorage.token;
  
      let data = {
          token: token,
      }

      this.axios.post(this.url + "checkToken.php", data).then((responce) => {
          if(responce.data == 200){
              this.showAdmin = true;
          }else{
              this.showAdmin = false;
          }
      });
            
  },

  editar_foto_upload(e){
    e.preventDefault();

    if(this.selectedFile != null){
        const fd = new FormData();
        fd.append('file',this.selectedFile, this.selectedFile.name);
        
        this.axios.post(this.url + 'uploadNew.php', fd, {header:{'Content-Type': 'multipart/form-data'}}).then((responce) =>{
          //console.log(responce);
          if(responce.data.res == '200'){
            let file = responce.data.nombre;
            this.editar_foto_alta(file);
          }
        })
    };
  },

  editar_foto_alta(file){
    let data = {
      flag: 2,
      id: this.id_integrante,
      ex_img: this.ex_img,
      file
    }
    
    this.axios.post(this.url + "altaIntegrantes.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.modal3.hide();
          this.getData();
          this.msjOk();
        }
    });
  },

  showModalEdicionFoto(f){
    this.modal3.show();
    this.id_integrante = f.id_integrante;
    this.ex_img = f.url_img;
  },
  
  editar(e){
    e.preventDefault();

    let data = {
      flag: 3,
      nombre: this.enombre,
      apellido: this.eapellido ,
      detalle: this.edetalle,
      detalle_eng: this.edetalle_eng,
      titulo: this.etitulo,
      id: this.id_integrante
    }

    this.axios.post(this.url + "altaIntegrantes.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.modal2.hide();
          this.getData();
          this.msjOk();
        }
    });
  },

  showModalEdicion(f){
    this.enombre = f.nombre;
    this.eapellido = f.apellido;
    this.edetalle = f.descr;
    this.edetalle_eng= f.descr_eng;
    this.etitulo= f.titulo;
    this.id_integrante = f.id_integrante;
    this.modal2.show();
  },

  addFile(event){
    this.selectedFile = event.target.files[0];
    this.fileName = this.selectedFile.name;
  },

  terminar(){
    this.showAdmin = false;
    this.setInit();
  },

  borrar(i){

    let data = {
      flag: 1,
      id:i.id_integrante,
      file: i.url_img
    }
    
    this.axios.post(this.url + "altaIntegrantes.php", data).then((responce) => {
        if(responce.data == 200){
          this.getData();
          this.msjOk("Integrande borrado con éxito");
        }
    });
  },

  getInstrumentos(){
      let data = {
        flag: 1,
      }

      this.axios.post(this.url + "getIntegrantes.php", data).then((responce) => {
          //console.log(responce.data);
          this.instrumentos = responce.data;
      });
  },

  altaInfo(file){
    let data = {
      flag: 0,
      nombre : this.nombre,
      apellido : this.apellido,
      titulo : this.titulo,
      detalle: this.detalle,
      detalle_eng: this.detalle_eng,
      file
    }

    this.axios.post(this.url + "altaIntegrantes.php", data).then((responce) => {
        if(responce.data == 200){
          this.modal.hide();
          this.getData();
          this.nombre = "";
          this.apellido = "";
          this.titulo = "";
          this.detalle = "";
          this.detalle_eng = "";
          this.msjOk("Integrande dato de alta con éxito");
        }
    });
  },
  
  alta(e){
    e.preventDefault();
    
    if(this.selectedFile != null){
        const fd = new FormData();
        fd.append('file',this.selectedFile, this.selectedFile.name);
        
        this.axios.post(this.url + 'uploadNew.php', fd, {header:{'Content-Type': 'multipart/form-data'}}).then((responce) =>{
          //console.log(responce);
          if(responce.data.res == '200'){
            let file = responce.data.nombre;
              this.altaInfo(file);
            }
        });
    };

  },

},
mounted(){
  this.getData();
  this.getInstrumentos();
  this.checkToken();

  this.modal = new Modal(this.$refs.exampleModal)
  this.modal2 = new Modal(this.$refs.edicionModal)
  this.modal3 = new Modal(this.$refs.modalFoto)
  window.scrollTo(0, 0);
}
}
</script>


<style scoped>

.cuerpo{
  background:/* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.7), 
          rgba(0, 0, 0, 0.7)
        ),
        url('../assets/osa_calendario_cover.jpg');
    background-size: cover;
    position: relative;
    min-height: 80vh;
}

.section_int{
  padding-left: 0px;
  padding-top: 100px;
}

.txt_nombre{
  text-decoration: none;
}

.txt_nombre:hover{
  color: #daa23f;
  transition: 1s;
}

.footer_int{
  height: 100px;
}

.imagen{
  position: relative;
  width: 80%;
  height: 300px;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.padding_left{
  padding-left: 3%;
  padding-top: 45px;
}

.text_dela{
  position:relative; 
  top:-20px
}

.txt_001{
  line-height: 72px;
}

/* Medium-small devices (phones, 900px and down) */
@media only screen and (max-width: 980px) {
  
  .padding_left{
    padding-left: 0%;
  }

}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 700px) {
  
  .padding_left{
    padding-left: 0%;
    padding-top: 65px;
  }

  .imagen{
    width: 100%;
    height: 100%;
    margin-left: -8px;
    margin-right: 0px;
  }

  .section_int{
    padding-left: 80px;
  }

  .text_dela{
    top: -2px
  }

  .txt_001{
    line-height: 42px
  }
}

</style>