<template>
<div>

  <!--Menu Admin -->
  <div class="menuAdmin container" v-if="showAdmin == true">
    <div class="row p-3">
      <div class="col-sm-10 col-8">
        <button type="button" class="m-1 btn btn-sm btn-primary" @click="modal.show()">
          + Programa
        </button>
        <button type="button" class="m-1 btn btn-sm btn-primary" @click="modal2.show()">
          + Artista
        </button>
        <button type="button" class="m-1 btn btn-sm btn-primary" @click="modal3.show()">
          + Info
        </button>
      </div>
      <div class="col-sm-2 col-4">
        <button class="btn btn-danger float-end btn-sm" @click="terminar()">Terminar</button>
      </div>
    </div>
  </div>


  <!--Menu -->
  <div class="cuerpo">
    <Menu :type='1'/>
    <div class="texto-hero pt-4 container-fluid">
        
        <div class="row" v-for="f in concierto" :key = "f.id_concierto">
          <div class="col-sm-12">
          
            <p class="return_padding">
              <router-link to="/Conciertos" class="link white texto-normal-bold s18">
                  <i class="fas fa-less-than amarillo"></i> &nbsp;&nbsp; {{conciertos}}
              </router-link>
            </p>
            
            <p class="text-center texto-normal-bold s16 upper white" style="line-height:100%;letter: spacing 0.02em;">  
              <span style="text-transform: capitalize;"> {{getDiaNombre(f.fecha_concierto)}} 
              {{getDia(f.fecha_concierto)}} 
              {{getMes(f.fecha_concierto)}}, 
              {{getAno(f.fecha_concierto)}}</span> 
            </p>
            
            <h1 class="pt-5 text-center texto-times-bold white s60">
              <span v-if="idioma == 'ES'"> {{f.nombre}} </span>
              <span v-else> {{f.nombre_eng}} </span>
            </h1>

            <p class="pt-5 text-center" style="padding-bottom:80px">
              <a :href="f.url_ticket" style="color:black;" class="link texto-normal-bold fw900 s12 boton-enviar">{{comprar_boletos}}</a>
            </p>
          </div>
        </div>

    </div>
  </div>

  <div class="separador-chico"></div>

  <div class="container-fluid">
    <div class="padding-all">
      <div class="cuerpo2 pb-7" style="padding-top:120px">
        <div class="row">
          <div class="offset-sm-2 col-sm-8">
            <p class="texto-normal-bold pb-4 pt-5 s43 upper" style="line-height:100%">{{programa_titulo}}</p>
            
            <div class="row" v-for="f in programa" :key="f.id_programa">
              <div class="col-sm-6">
                <p class="texto-normal-bold s20 amarillo">
                  <button v-if="showAdmin == true" @click="borrar(f)" class="btn btn-danger btn-sm">X</button>
                  {{f.compositor}}
                </p>
              </div>
              <div class="col-sm-6">
                <p class="texto-normal s20" style="white-space:pre-wrap; line-height: 160%;" >
                  <span v-if="idioma == 'ES'"> {{f.pieza}} </span>
                  <span v-else> {{f.pieza_eng}} </span>
                </p>
              </div>
              <hr>
            </div>

            <p class="texto-normal-bold pb-4 pt-5 s43 upper" style="line-height:100%">{{artista_titulo}}</p>
            <div class="row" v-for="f in artista" :key="f.id_programa">
              <div class="col-sm-6">
                <p class="texto-normal s20">
                  <button v-if="showAdmin == true" @click="borrar_artista(f)" class="btn btn-danger btn-sm">X</button>
                  {{f.nombre}}
                </p>
              </div>
              <div class="col-sm-6">
                <p class="texto-normal-bold upper s20">
                  <span v-if="idioma == 'ES'"> {{f.titulo}} </span>
                  <span v-else> {{f.titulo_eng}} </span>
                </p>
              </div>
              <hr>
            </div>

            <p class="texto-normal-bold pb-4 pt-5 s43 upper" style="line-height:100%">{{informacion_titulo}}</p>
            <div class="row pb-5">
              <div class="col-sm-10">
                <p class="texto-normal pb-5 s20" style="white-space:pre-wrap;">
                  <span v-if="idioma == 'ES'"> {{info_array}} </span>
                  <span v-else> {{info_array_eng}} </span>
                </p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    
  </div>

  <div class="separador"></div>

<!-- Modal Alta Programa-->
<div class="modal fade" ref="exampleModal" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Nuevo Programa</h5>
        <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
      </div>
      <form @submit="alta_programa">
        <div class="modal-body">
          <div class="container-fluid">
          
            <div class="row">
              <div class="col-sm-6">
                <span class="badge bg-secondary">Compositor</span>
                <input type="text" class="mt-1 form-control" required v-model="compositor">
              </div>
            </div> <br>

            <div class="row">
              <div class="col-sm-6">
                <span class="badge bg-secondary">Pieza Musical</span>
                <!--<input type="text" class="mt-1 form-control" required v-model="pieza">-->
                <textarea class="mt-1 form-control" required v-model="pieza" rows="5"></textarea>
              </div>
              <div class="col-sm-6">
                <span class="badge bg-secondary">Pieza Musical Inglés</span>
                <!--<input type="text" class="mt-1 form-control" required v-model="pieza_eng">-->
                <textarea class="mt-1 form-control" required v-model="pieza_eng" rows="5"></textarea>
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal.hide()">Cerrar</button>
          <button type="submit" class="btn btn-primary">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</div>


<!-- Modal Alta Artista -->
<div class="modal fade" ref="modalArtista" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Nuevo Artista</h5>
        <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
      </div>
      <form @submit="alta_artista">
        <div class="modal-body">
          <div class="container-fluid">

            <div class="row">
              <div class="col-sm-6">
                <span class="badge bg-secondary">Artista</span>
                <input type="text" class="mt-1 form-control" required v-model="nombre_artista">
              </div>
              <div class="col-sm-6"></div>
              
              <div class="col-sm-6 pt-2">
                  <span class="badge bg-secondary">Instrumento/Puesto</span>
                  <input type="text" class="form-control" v-model="titulo">
                  <!--<select class="form-control" required v-model="titulo">
                    <option :value="f.id_instrumento" v-for="f in instrumentos" :key="f.id_instrumento">{{f.nombre_ins}}</option>
                  </select>-->
              </div>
              <div class="col-sm-6 pt-2">
                  <span class="badge bg-secondary">Instrumento/Puesto (Ingles)</span>
                  <input type="text" class="form-control" v-model="titulo_eng">
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal2.hide()">Cerrar</button>
          <button type="submit" class="btn btn-primary">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</div>


<!-- Modal Alta Info -->
<div class="modal fade" ref="modalInfo" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Información</h5>
        <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
      </div>
      <form @submit="alta_info">
        <div class="modal-body">
          <div class="container-fluid">

            <div class="row">
              <div class="col-sm-12">
                <span class="badge bg-secondary">Información</span>
                <textarea rows="5" class="mt-1 form-control" required v-model="info" />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 mt-2">
                <span class="badge bg-secondary">Información Inglés</span>
                <textarea rows="5" class="mt-1 form-control" required v-model="info_eng" />
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal3.hide()">Cerrar</button>
          <button type="submit" class="btn btn-primary">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</div>


</div>
</template>


<script>
import Menu from '@/components/Menu'
import { Modal } from 'bootstrap'
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'

export default {
name: 'ConciertoDetalle',
mixins: [ globalFunctions ],
components:{ Menu },
data () {
  return {
    idioma: localStorage.idioma,
    modal: null,
    modal2: null,
    showAdmin: '',
    id: this.$route.params.id,
    compositor: '',
    pieza: '',
    pieza_eng:'',
    programa: '',
    instrumentos: '',
    nombre_artista: '',
    titulo: '',
    titulo_eng: '',
    artista: '',
    concierto: '',
    info: '',
    info_eng: '',
    info_array: '',
    info_array_eng: '',
    /****Traducciones****/
    conciertos: 'Conciertos',
    comprar_boletos: 'COMPRAR BOLETOS',
    programa_titulo: 'programa',
    artista_titulo: 'artista',
    informacion_titulo: 'información'
  }
},
computed:{
  ...mapState(['url'])
},
methods: {
  getData(){
    let data = {
        flag: 0,
        id: this.id
    }

    this.axios.post(this.url + "getPrograma.php", data).then((responce) => {
        //console.log(responce.data);
        this.programa = responce.data;
    });

    let data2 = {
        flag: 1,
        id: this.id
    }
    
    //Artista
    this.axios.post(this.url + "getConcierto.php", data2).then((responce) => {
        //console.log(responce.data);
        this.artista = responce.data;
    });

    let data3 = {
        flag: 2,
        id: this.id
    }

    this.axios.post(this.url + "getConcierto.php", data3).then((responce) => {
        //console.log(responce.data);
        this.info_array = responce.data[0].texto;
        this.info_array_eng = responce.data[0].texto_eng;
    });

    let data4 = {
        flag: 4,
        id: this.id
    }
    

    /*Lista de Conciertos */
    this.axios.post(this.url + "getConcierto.php", data4).then((responce) => {
        //console.log(responce.data);
        this.concierto = responce.data;
    });
  },

  alta_info(e){
    e.preventDefault();

    let data = {
      flag: 4,
      id: this.id,
      info: this.info,
      info_eng: this.info_eng,
    }
    
    this.axios.post(this.url + "altaConcierto.php", data).then((responce) => {
        if(responce.data == 200){
          this.getData();
          this.modal3.hide();
          this.msjOk("Informació dada de alta con éxito");
          this.clean();
        }
    });
  },
  clean(){
    this.compositor= "";
    this.pieza = "";
    this.pieza_eng = "";
    this.programa = "";
    this.instrumentos = "";
    this.nombre_artista= "";
    this.titulo = "";
    this.artista = "";
    this.info_array = "";
    this.info_array_eng = "";
  },

  alta_programa(e){
    e.preventDefault();

    let data = {
      flag: 0,
      id: this.id,
      compositor: this.compositor,
      pieza: this.pieza,
      pieza_eng: this.pieza_eng
    }
    
    this.axios.post(this.url + "altaPrograma.php", data).then((responce) => {
        if(responce.data == 200){
          this.getData();
          this.modal.hide();
          this.msjOk("Programa dato de alta con éxito");
          this.clean();
        }
    });

  },
  alta_artista(e){
    e.preventDefault();

    let data = {
      flag: 0,
      artista: this.nombre_artista,
      titulo: this.titulo,
      titulo_eng : this.titulo_eng,
      id: this.id,
    }
    
    this.axios.post(this.url + "altaArtista.php", data).then((responce) => {
        //console.log(responce.data);
        if(responce.data == 200){
          this.getData();
          this.modal2.hide();
          this.msjOk("Artista dado de alta con éxito");
          this.clean();
        }
    });

  },
  borrar_artista(f){
    let data = {
      flag: 3,
      id: f.id_artista,
    }
    
    this.axios.post(this.url + "altaConcierto.php", data).then((responce) => {
        if(responce.data == 200){
          this.getData();
          this.msjOk("Artista borrado con éxito");
        }
    });
  },
  borrar(f){

    let data = {
      flag: 1,
      id: f.id_programa,
    }
    
    this.axios.post(this.url + "altaPrograma.php", data).then((responce) => {
        if(responce.data == 200){
          this.getData();
          this.msjOk("Programa borrado con éxito");
        }
    });

  },
  getPuesto(){
      let data = {
        flag: 1,
      }

      this.axios.post(this.url + "getIntegrantes.php", data).then((responce) => {
          //console.log(responce.data);
          this.instrumentos = responce.data;
      });
  },
  getEnglish(){
    this.conciertos = 'Concerts';
    this.comprar_boletos = 'BUY TICKETS';
    this.programa_titulo = 'program';
    this.artista_titulo = 'artist';
    this.informacion_titulo = 'information';

  },
  terminar(){
    this.showAdmin = false;
    this.setInit();
  },
  checkToken(){
      const token = localStorage.token;
  
      let data = {
          token: token,
      }

      this.axios.post(this.url + "checkToken.php", data).then((responce) => {
          if(responce.data == 200){
              this.showAdmin = true;
          }else{
              this.showAdmin = false;
          }
      });
            
  }
},
mounted(){
  this.modal = new Modal(this.$refs.exampleModal)
  this.modal2 = new Modal(this.$refs.modalArtista)
  this.modal3 = new Modal(this.$refs.modalInfo)
  
  this.checkToken();

  window.scrollTo(0, 0);
  this.getData();
  this.getPuesto();
  
  if(this.idioma == 'EN'){
    this.getEnglish();
  }
}
}
</script>


<style scoped>

.cuerpo{
  background:/* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.7), 
          rgba(0, 0, 0, 0.7)
        ),
        url('../assets/osa_convocatorias_cover.jpg');
    background-size: cover;
    position: relative;
    min-height: 80vh;
}

.cuerpo2{
  background-color: white;
  /*padding-left: 2%;*/
}

.img{
  position: relative;
  width: 640px;
  height: 451px;
  float: right;
}

.return_padding{
  padding-left: 8%;
  padding-top: 5%;
  padding-bottom: 4%;
}

/* Medium-small devices (phones, 900px and down) */
@media only screen and (max-width: 900px) {


}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .return_padding{
    padding: 2%;
  }
}

</style>