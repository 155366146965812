<template>
  
  <div class="container-fluid" style="background-color: #0C0D0E;">
    <div class="footer pt-7">
      
      <div class="row section1">
        <div class="col-xl-4 col-lg-4 col-md-12">
          <div class="section1-a">
            <!--<p class="texto-normal-bold s60 txt_001" v-if="idioma == 'ES'" >SÍGUENOS <br> <span class="texto-times-italic s38 txt_002 "> en </span> REDES</p>
            <p class="texto-normal-bold s60 txt_001" v-else >FOLLOW US <span class="texto-times-italic s38 txt_002 "> on </span> SOCIAL MEDIA</p> -->
            <p class="texto-normal-bold s43 txt_001" v-if="idioma == 'ES'" >SÍGUENOS</p>
            <p class="texto-normal-bold s43 txt_001" v-else >FOLLOW US </p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 section1-b">
          <p class="texto-times-italic redes1 text-center">
            <a href="https://www.facebook.com/OrquestaSinfonicadeAguascalientes" target="_blank" class="link">
              <span class="href-chica txt_section1"> 
                Facebook
              </span> 
            </a>
            <a href="https://www.facebook.com/OrquestaSinfonicadeAguascalientes" target="_blank" class="link_social">
              <span class="icon icon_001">
                <i class="fab fa-facebook-f"></i>
              </span>
            </a>
          </p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 section1-b">
            <p class="texto-times-italic text-center">
              <a href="https://www.instagram.com/orquesta_sinfonica_ags/" class="link" target="_blank">
                <span class="href-chica txt_section1"> 
                  Instagram
                </span>
              </a>
              <a href="https://www.instagram.com/orquesta_sinfonica_ags/" class="link_social" target="_blank">
                <span class="icon icon_002">
                  <i class="fab fa-instagram"></i>
                </span>
              </a>
            </p>
        </div>
       <!-- <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12 section1-b">
          <p class="texto-times-italic redes3 text-center">
            <a href="#" class="link" target="_blank">
              <span class="href-chica txt_section1"> 
                Youtube
              </span> 
            </a>
            <a href="#" class="link_social" target="_blank">
              <span class="icon icon_003">
                <i class="fab fa-youtube"></i>
              </span>
            </a>
          </p>
        </div>-->
      </div>

      <div class="row section2">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div>
            <img src="../assets/Logo_OSA.png" class="logo_footer"  alt="">
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 pt-lg-0 pt-md-2 pt-sm-4 pt-5">
          <p class="texto-times titulo-section2">Teatro Aguascalientes</p>
          <p class="texto-normal texto-section2"> Av. José María Chávez S/N, <br> Jardines de la Asunción, 20270 <br> Aguascalientes, Ags. México</p>
        </div>
        <div class="col-lg-4 offset-lg-0 offset-md-6 col-md-6 offset-sm-0 col-sm-12 section2-b" >
          <p class="texto-times titulo-section2">Sala de Conciertos</p>
          <p class="texto-normal texto-section2">Desarrollo Especial Talleres F.F.C.C. <br> (Complejo Ferrocarrilero Tres Centurias) <br> 20270 Aguascalientes, Ags. México</p>
        </div>
      </div>

      <div class="section3">
        <p class="text-center">
          © 2022 Orquesta Sinfónica de Aguascalientes. 
        </p>
      </div>
      

    </div>
  </div>
  

</template>

<script>
import Menu from '@/components/Menu'
import { globalFunctions } from '@/hooks/globalFunctions'
import { mapState } from 'vuex'

export default {
name: 'Footer',
mixins: [ globalFunctions ],
components:{ Menu },
data () {
  return {
    idioma: localStorage.idioma,
    showAdmin: '',
  }
},
computed:{
  ...mapState(['url'])
},
methods: {},
mounted(){}
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer{
    background-color: #0C0D0E;
    /*height: 75px;*/
    color: white;
    margin-left: 8%;
    margin-right: 8%;
    border-bottom: 1px solid #0C0D0E;;
}

.icon{
  font-size: 26px;
}

.link_social{
  color: white;
}

.link_social:hover{
  color: #daa23f;
  transition: 1s;
}

.item_redes{
  float: right;
}

.section1{
  /*border: 1px solid red;*/
  border-bottom: 1px solid gray;
  padding-bottom: 55px;
  padding-top: 28px;
}

.section2{
  padding-top: 48px;
  padding-bottom: 48px;
  border-bottom: 1px solid gray;
}

.section3{
  padding-top: 28px;
  padding-bottom: 108px;
  font-size: 16px;
}

.section1-b{
  position: relative;
  /*top: 45px;*/
}

.txt_001{
  line-height: 59px !important;
}

.txt_section1{
  font-size: 26px;
  color: #EBEBEB;
}

.texto-section2{
  font-size: 18px;
  color: #EBEBEB;
  line-height: 139%;
  font-weight: normal !important;
}

.txt_002{
  position: relative;
  top: -15px;
}

.titulo-section2{
  font-size: 26px;
}

.icon_001{
    border: 2px solid #daa23f;
    border-radius: 28px;
    padding-left: 18px; 
    padding-right: 18px;
    padding-top: 10px; 
    padding-bottom: 10px;
    margin-left: 15px;
}

.icon_002{
    border: 2px solid #daa23f;
    border-radius: 28px;
    padding-left: 16px; 
    padding-right: 16px;
    padding-top: 10px; 
    padding-bottom: 10px;
    margin-left: 15px;
}

.icon_003{
    border: 2px solid #daa23f;
    border-radius: 33px;
    padding-left: 15px; 
    padding-right: 15px;
    padding-top: 12px; 
    padding-bottom: 12px;
    margin-left: 15px;
}

.redes3{
  float: right;
}

.redes1{
  /*float: left;*/
  margin-left: 10px;
}

/* Medium-small devices (phones, 900px and down) */
@media only screen and (max-width: 1180px) {
  
  .redes1{
    float: left;
  }

}

/* Medium-small devices (phones, 900px and down) */
@media only screen and (max-width: 980px) {
    .section1-a{
      text-align: center;
    }

    .item_redes{
      float: left;
    }

    .section1-b{
      position: relative;
      top: 0px;
      padding-top: 45px;
    }

    .section2-b{
      position: relative;
      padding-top: 25px;
    }

    .txt_section1{
      font-size: 20px;
    }

    .texto-section2{
      font-size: 16px;
    }

    .redes3{
      float:unset !important;
    }

    .redes1{
      float: unset !important;
      margin-left: 0px;
    }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 700px) {

  .texto-section2{
      text-align: center;
  }

  .section1-b{
    padding-top: 25px;
  }

  .titulo-section2{
    text-align: center;
  }

  .logo_footer{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .redes3{
    float:unset !important;
  }

  .redes1{
    float: unset !important;
    margin-left: 0px;
  }

  .txt_001{
    line-height: 38px !important;
  }

  .txt_002{
    font-size: 35px !important;
  }
}

</style>
