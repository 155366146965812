import { mapState } from 'vuex'

export const globalFunctions = {
    computed:{
        ...mapState(['url'])
    },
    data () {
        return {
            sesion: false
        }
    },      
    methods: {
        //Mensaje Ok
        msjOk(msg = 'Informacion Actualizada'){
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: msg,
                showConfirmButton: false,
                timer: 1500
            });
        },

        loadingModal(){
            this.$swal.showLoading();
        },

        closeLoadingModal(){
            this.$swal.close();
        },

        //Mensaje en caso de error
        msjError(msg){
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: msg,
                showConfirmButton: false,
                timer: 2000
            });
        },

        //Filtros
        moneda(value) {
            let val = (value/1).toFixed(2).replace(',', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        
        fechaFormat(date){
            let anio = date.substr(0,4);
            let mes = date.substr(5,2);
            let dia = date.substr(8,2);
            
            let newDate = dia + '/' + mes + '/' + anio;
            return newDate;
        },

        addZeros(num){
            let i = true;
            
            while(i==true){
              if(num.length < 5){
                  num = '0' + num;
              }else{
                  i=false;
              }
            }
            return num;
        },


        //Regresa un int
        makeNumber(num){
            let i = Number(num);
            return i;
        },

        //Regresa nombre de la fecha
        getDiaNombre(fecha){
            
            let date = new Date(fecha);
            let dia = date.getDay();
            let idioma = localStorage.idioma;
            let dianombre = "";
            let result = "";
            let result_eng = "";
            
            //console.log(dia);

            switch(dia) {
                case 0:
                    result = "lunes";
                    result_eng = "monday";
                    break;
                case 1:
                    result = "martes";
                    result_eng = "tuesday";
                break;
                case 2:
                    result = "miercoles";
                    result_eng = "wednesday";
                break;
                case 3:
                    result = "jueves";
                    result_eng = "thursday";
                    break;
                case 4:
                    result = "viernes";
                    result_eng = "friday";
                    break;
                case 5:
                    result = "sabado";
                    result_eng = "saturday";
                    break;
                case 6:
                    result = "domingo";
                    result_eng = "sunday";
                    break;
            }

            if (idioma == 'ES'){
                dianombre = result;
            }else {
                dianombre = result_eng;
            }
            
            return dianombre;
        },

        //Regresa nombre de la fecha
        getDia(fecha){
            let result = fecha.substr(8, 2);
            return result
        },

        //Regresa nombre de la fecha
        getMes(fecha){
            let date = fecha.substr(5, 2);
            let result = "";
            let result_eng = "";
            let idioma = localStorage.idioma;
            let mes = "";

            switch(date) {
                case '01':
                    result = "enero";
                    result_eng = "january";
                    break;
                case '02':
                    result = "febrero";
                    result_eng = "febuary";
                    break;
                case '03':
                    result = "marzo";
                    result_eng = "march";
                break;
                case '04':
                    result = "abril";
                    result_eng = "april";
                break;
                case '05':
                    result = "mayo";
                    result_eng = "may";
                    break;
                case '06':
                    result = "junio";
                    result_eng = "june";
                    break;
                case '07':
                    result = "julio";
                    result_eng = "july";
                break;
                case '08':
                    result = "agosto";
                    result_eng = "august";
                break;
                case '09':
                    result = "septiembre";
                    result_eng = "september";
                    break;
                case '10':
                    result = "octubre";
                    result_eng = "october";
                    break;  
                case '11':
                    result = "noviembre";
                    result_eng = "november";
                break;
                case '12':
                    result = "diciembre";
                    result_eng = "december";
                break;
            }
            
            if (idioma == 'ES'){
                mes = result;
            }else {
                mes = result_eng;
            }

            return mes;
        },

        getAno(fecha){
            let result = fecha.substring(0, 4);
            return result;
        },

        addFiveDaysToday() {

            var date = new Date();        
            date.setDate(date.getDate() + 6);

            var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            var maxDate = [year, month, day].join('-');

            return maxDate;

        },

        setInit(){
            localStorage.token = '';
        },

        checkDate(date){
            let today = new Date();
            let ano = today.getFullYear();
            let mes = today.getMonth()+1;
            let dia = today.getDate();            
            var ret = 0;

            if(mes < 10) {
                mes = '0' + mes;
            }

            if(dia < 10){
                dia = '0' + dia;
            }

            var datehoy = ano+'-'+mes+'-'+dia;
            
            if(date >= datehoy){
                ret = 0 //ok
            }else{
                /*var date1x = new Date(date);
                var date2x = new Date(datehoy);
                const diffTime = Math.abs(date2x - date1x);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                console.log(diffDays + " days");*/
                ret = 1 //error date
            }
            
            return ret;
        },
        
        /*checkToken(){
            const token = localStorage.token;
        
            let data = {
                token: token,
            }

            this.axios.post(this.url + "checkToken.php", data).then((responce) => {
                if(responce.data == 200){
                    this.sesion = true;
                    return this.sesion;
                }else{
                    this.sesion = false;
                    return this.sesion;
                }
            });
            
        }*/
    },
}